interface EllipseDrawingViewProps {
    width: number
    height: number
    left: number
    top: number
}

export const EllipseDrawingView = (props: EllipseDrawingViewProps) => {
    var circleStyle = {
        padding: 0,
        margin: 0,
        display: 'inline-block',
        borderRadius: '50%',
        backgroundColor: 'white',
        width: props.width,
        height: props.height,
        left: props.left - props.width / 2,
        top: props.top - props.height / 2,
    }

    return <div style={{ position: 'absolute', ...circleStyle }}></div>
}
