import { Expose } from 'class-transformer'
import { IsDefined, IsNumber, ValidateIf } from 'class-validator'

export class Vector {
    @Expose()
    @IsDefined()
    @IsNumber()
    x!: number

    @Expose()
    @IsDefined()
    @IsNumber()
    y!: number

    @Expose()
    @IsDefined()
    @ValidateIf((object, value) => value !== null)
    flags?: string | null
}
