import { ChangeEvent, createRef, MouseEvent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { forgotPassword, login } from '../../action'
import { LoadingIcon } from '../../component/icon'
import { EmailField } from '../../component/field'
import { State } from '../../state'
import { FORGOTTEN_PASSWORD_ACTIONS } from '../../action/users/users.constants'
import { useTranslation } from 'react-i18next'
import { validateEmail } from '../../helper/validate.helper'
import './forgot-password.page.scss'

export const ForgotPasswordPage = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { form, sendStarted, forgotPasswordEmailRequested } = useSelector((state: State) => state.forgotPassword)
    const formElement = createRef<HTMLFormElement>()

    const [emailError, setEmailError] = useState("")

    const onEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        dispatch({ type: FORGOTTEN_PASSWORD_ACTIONS.FORGOTTEN_PASSWORD_CHANGE_EMAIL, email: event.target.value })
    }

    const onSendButtonClick = async (event: MouseEvent) => {
        event.preventDefault()

        if (!validateFileds()){
            return
        }
        
        if (!formElement.current?.reportValidity()) {
            return
        }

        await forgotPassword()
    }

    const validateFileds = () => {
        setEmailError("")

        const emailValidation = validateEmail(form.email.value)
        if (emailValidation != null) {
            setEmailError(t(emailValidation))
            return false
        }

        return true
    }

    return (
        <main className="page--forgot-password">
            <form action="" className="forgot-password-form" ref={formElement}>
                {forgotPasswordEmailRequested !== true ? (
                    <div>
                        <div className="title-container">
                            <NavLink to="/login" onClick={(e) => dispatch({ type: FORGOTTEN_PASSWORD_ACTIONS.FORGOTTEN_PASSWORD_CHANGE_EMAIL, email: '' })}>
                                <button className="button-back">
                                    <img alt="Vissza gomb" className="back" src="/image/ic_arrow_left.svg" />
                                </button>
                            </NavLink>

                            <h2>{t('forgot_password_title')}</h2>
                        </div>

                        <label className="description">{t('forgot_password_description')}</label>

                        <EmailField field={form.email} onChange={onEmailChange} placeholder={t('login_email_placeholder')} />
                        <p className={`field-message${emailError.length > 0 ? ` field-message--error` : ``}`}>{emailError.length > 0 ? emailError : t('login_email_hint')}</p>

                        <footer>
                            <button className="button" disabled={sendStarted !== null} onClick={onSendButtonClick} type="submit">
                                {sendStarted !== null ? <LoadingIcon /> : t('forgot_password_send_button')}
                            </button>
                        </footer>
                    </div>
                ) : (
                    <>
                        <h2>{t('forgot_password_success_title')}</h2>

                        <label className="description">{t('forgot_password_success_description')} </label>

                        <footer>
                            <button
                                className="button"
                                onClick={(e) => {
                                    e.preventDefault()
                                    dispatch({ type: FORGOTTEN_PASSWORD_ACTIONS.FORGOTTEN_PASSWORD_FAILED })
                                    navigate(`/login`)
                                }}
                            >
                                {t('forgot_password_back_button')}
                            </button>
                        </footer>
                    </>
                )}
            </form>
        </main>
    )
}
