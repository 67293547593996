import { useDispatch, useSelector } from 'react-redux'
import { ChangeEvent, MouseEvent, KeyboardEvent, useState } from 'react'
import { SearchField } from '../../component/field'
import { WarrantyGroupsTable } from './component'
import { CREATE_WARRANTY_ACTIONS, GET_WARRANTY_GROUPS_ACTIONS } from '../../action/warranties/warranties.constants'
import { getWarrantyGroups } from '../../action'
import { useTranslation } from 'react-i18next'
import { State } from '../../state'
import { AddWarrantyModal } from './component/add-warranty-modal'
import { UserType } from '../../enum/user-type.enum'
import './warranty-groups.page.scss'

interface WarrantyGroupPageProps {}

export const WarrantyGroupPage = (props: WarrantyGroupPageProps) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const { search } = useSelector((state: State) => state.warrantyGroup)
    const [addWarrantyModalVisible, setAaddWarrantyModalVisible] = useState(false)
    const authenticatedUser = useSelector((state: State) => state.session.authenticatedUser)

    const onAddWarrantyButtonClick = async (event: MouseEvent | KeyboardEvent) => {
        event.preventDefault()
        setAaddWarrantyModalVisible(!addWarrantyModalVisible)
    }

    const onAddWarrantyModalClose = () => {
        dispatch({ type: CREATE_WARRANTY_ACTIONS.CREATE_WARRANTY_CLEAR })
        setAaddWarrantyModalVisible(!addWarrantyModalVisible)
    }

    const onSearchChange = async (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()

        dispatch({ type: GET_WARRANTY_GROUPS_ACTIONS.GET_WARRANTY_GROUPS_SEARCH, search: event.target.value })

        if (event.target.value.length === 0) {
            await getWarrantyGroups()
        }
    }

    const onSearchButtonClick = async (event: MouseEvent | KeyboardEvent) => {
        event.preventDefault()

        await getWarrantyGroups()
    }

    return (
        <main className="page page-warranty-group">
            <div className="main-div">
                <div className="navigation-bar-div">
                    <div className="navigation-bar-title-div">
                        <h1>{t('warranty_groups_title')}</h1>
                        <p>{t('warranty_groups_description')}</p>
                    </div>
                    {authenticatedUser?.role === UserType.USER || authenticatedUser?.role === UserType.DEALER ? (
                        <div className="navigation-bar-button-div">
                            <button className="button" onClick={onAddWarrantyButtonClick}>
                                {t('warranty_groups_add_button')}
                            </button>
                        </div>
                    ) : null}
                </div>

                <div className="search-bar-div">
                    <SearchField value={search} onSearch={onSearchButtonClick} onChange={onSearchChange} placeholder={t('search_placeholder')} />

                    <button className="button" onClick={onSearchButtonClick}>
                        {t('search_button')}
                    </button>
                </div>

                <WarrantyGroupsTable />
            </div>

            {addWarrantyModalVisible ? <AddWarrantyModal onModalClose={onAddWarrantyModalClose} onCloseClick={onAddWarrantyModalClose} /> : null}
        </main>
    )
}
