import { FORGOTTEN_PASSWORD_ACTIONS } from '../action/users/users.constants'
import { ForgotPasswordState } from '../state/forgot-password.state'

const initialForgotPasswordState: ForgotPasswordState = {
  form: {
    email: {
      error: '',
      value: '',
    },
  },
  sendStarted: null,
  forgotPasswordEmailRequested: null,
}

export const forgotPasswordReducer = (state: ForgotPasswordState = initialForgotPasswordState, action: any): ForgotPasswordState => {
  switch (action.type) {
    case FORGOTTEN_PASSWORD_ACTIONS.FORGOTTEN_PASSWORD_CHANGE_EMAIL:
      return { ...state, form: { ...state.form, email: { ...state.form.email, value: action.email } } }
    case FORGOTTEN_PASSWORD_ACTIONS.FORGOTTEN_PASSWORD_STARTED:
      return { ...state, sendStarted: new Date(), forgotPasswordEmailRequested: false }
    case FORGOTTEN_PASSWORD_ACTIONS.FORGOTTEN_PASSWORD_OK:
      return { ...state, sendStarted: null, forgotPasswordEmailRequested: true, form: { ...state.form, email: { ...state.form.email, value: '' } } }
    case FORGOTTEN_PASSWORD_ACTIONS.FORGOTTEN_PASSWORD_FAILED:
      return { ...state, sendStarted: null, forgotPasswordEmailRequested: null }
    default:
      return state
  }
}
