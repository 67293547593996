export const LOIGN_ACTIONS = {
    LOGIN_CHANGE_EMAIL: 'LOGIN_CHANGE_EMAIL',
    LOGIN_CHANGE_PASSWORD: 'LOGIN_CHANGE_PASSWORD',
    LOGIN_STARTED: 'LOGIN_STARTED',
    LOGIN_OK: 'LOGIN_OK',
    LOGIN_FAILED: 'LOGIN_FAILED',
}

export const FORGOTTEN_PASSWORD_ACTIONS = {
    FORGOTTEN_PASSWORD_CHANGE_EMAIL: 'FORGOTTEN_PASSWORD_CHANGE_EMAIL',
    FORGOTTEN_PASSWORD_STARTED: 'FORGOTTEN_PASSWORD_STARTED',
    FORGOTTEN_PASSWORD_OK: 'FORGOTTEN_PASSWORD_OK',
    FORGOTTEN_PASSWORD_FAILED: 'FORGOTTEN_PASSWORD_FAILED',
}

export const CHANGE_PASSWORD_ACTIONS = {
    CHANGE_PASSWORD_CHANGE_OLD_PASSWORD: 'CHANGE_PASSWORD_CHANGE_OLD_PASSWORD',
    CHANGE_PASSWORD_CHANGE_NEW_PASSWORD: 'CHANGE_PASSWORD_CHANGE_NEW_PASSWORD',
    CHANGE_PASSWORD_CHANGE_NEW_PASSWORD_AGAIN: 'CHANGE_PASSWORD_CHANGE_NEW_PASSWORD_AGAIN',
    CHANGE_PASSWORD_CLEAR: 'CHANGE_PASSWORD_CLEAR',
    CHANGE_PASSWORD_STARTED: 'CHANGE_PASSWORD_STARTED',
    CHANGE_PASSWORD_OK: 'CHANGE_PASSWORD_OK',
    CHANGE_PASSWORD_FAILED: 'CHANGE_PASSWORD_FAILED',
}

export const RESET_PASSWORD_ACTIONS = {
    RESET_PASSWORD_CHANGE_PASSWORD: 'RESET_PASSWORD_CHANGE_PASSWORD',
    RESET_PASSWORD_CHANGE_NEW_PASSWORD: 'RESET_PASSWORD_CHANGE_NEW_PASSWORD',
    RESET_PASSWORD_SET_TOKEN_AND_FORGOTTEN: 'RESET_PASSWORD_SET_TOKEN_AND_FORGOTTEN',
    RESET_PASSWORD_STARTED: 'FORGOTTEN_PASSWORD_STARTED',
    RESET_PASSWORD_OK: 'FORGOTTEN_PASSWORD_OK',
    RESET_PASSWORD_FAILED: 'FORGOTTEN_PASSWORD_FAILED',
}

export const LOGOUT_ACTIONS = {
    LOGOUT: 'LOGOUT',
}

export const GET_ALL_USER_ACTIONS = {
    GET_ALL_USER_CHANGE_PAGE: 'GET_ALL_USER_CHANGE_PAGE',
    GET_ALL_USER_CHANGE_ORDER: 'GET_ALL_USER_CHANGE_ORDER',
    GET_ALL_USER_CHANGE_ROLE: 'GET_ALL_USER_CHANGE_ROLE',
    GET_ALL_USER_SEARCH: 'GET_ALL_USER_SEARCH',
    GET_ALL_USER_STARTED: 'GET_ALL_USER_STARTED',
    GET_ALL_USER_OK: 'GET_ALL_USER_OK',
    GET_ALL_USER_FAILED: 'GET_ALL_USER_FAILED',
}

export const CREATE_USER_ACTIONS = {
    CREATE_USER_CHANGE_USER_ID: 'CREATE_USER_CHANGE_USER_ID',
    CREATE_USER_CHANGE_FIRST_NAME: 'GET_ALL_USER_CHANCREATE_USER_CHANGE_FIRST_NAMEGE_PAGE',
    CREATE_USER_CHANGE_LAST_NAME: 'CREATE_USER_CHANGE_LAST_NAME',
    CREATE_USER_CHANGE_EMAIL: 'CREATE_USER_CHANGE_EMAIL',
    CREATE_USER_CHANGE_ROLE: 'CREATE_USER_CHANGE_ROLE',
    CREATE_USER_CHANGE_DEALERSHIP: 'CREATE_USER_CHANGE_DEALERSHIP',
    CREATE_USER_CHANGE_LANGUAGE: 'CREATE_USER_CHANGE_LANGUAGE',
    CREATE_USER_CLEAR: 'CREATE_USER_CLEAR',
    CREATE_USER_STARTED: 'CREATE_USER_STARTED',
    CREATE_USER_OK: 'CREATE_USER_OK',
    CREATE_USER_FAILED: 'CREATE_USER_FAILED',
}

export const DELETE_USER_ACTIONS = {
    DELETE_USER_ACTIONS_STARTED: 'DELETE_USER_ACTIONS_STARTED',
    DELETE_USER_ACTIONS_OK: 'DELETE_USER_ACTIONS_OK',
    DELETE_USER_ACTIONS_FAILED: 'DELETE_USER_ACTIONS_FAILED',
}

export const UPDATE_USER_STATUS_ACTIONS = {
    UPDATE_USER_STATUS_STARTED: 'UPDATE_USER_STATUS_STARTED',
    UPDATE_USER_STATUS_OK: 'UPDATE_USER_STATUS_OK',
    UPDATE_USER_STATUS_FAILED: 'UPDATE_USER_STATUS_FAILED',
}

export const EXPORT_USERS_ACTION = {
    EXPORT_USERS_ACTION_STARTED: 'EXPORT_USERS_ACTION_STARTED',
    EXPORT_USERS_ACTION_OK: 'EXPORT_USERS_ACTION_OK',
    EXPORT_USERS_ACTION_FAILED: 'EXPORT_USERS_ACTION_FAILED',
}

export const VALIDATE_TOKEN_ACTION = {
    VALIDATE_TOKEN_ACTION_STARTED: 'VALIDATE_TOKEN_ACTION_STARTED',
    VALIDATE_TOKEN_ACTION_OK: 'VALIDATE_TOKEN_ACTION_OK',
    VALIDATE_TOKEN_ACTION_FAILED: 'VALIDATE_TOKEN_ACTION_FAILED',
}
