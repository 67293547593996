import { Expose, Type } from 'class-transformer'
import { IsDefined, IsNumber, ValidateNested } from 'class-validator'
import { User } from '../../entity'

export class UsersDTO {
    @Expose()
    @ValidateNested()
    @Type(() => User)
    data!: User[]

    @Expose()
    @IsDefined()
    @IsNumber()
    page!: number

    @Expose()
    @IsDefined()
    @IsNumber()
    pageSize!: number

    @Expose()
    @IsDefined()
    @IsNumber()
    count!: number
}
