import { LOIGN_ACTIONS } from '../action/users/users.constants'
import { LoginState } from '../state'

const initialLoginState: LoginState = {
    form: {
        email: {
            error: '',
            value: '',
        },
        password: {
            error: '',
            value: '',
        },
    },
    loginStarted: null,
}

export const loginReducer = (state: LoginState = initialLoginState, action: any): LoginState => {
    switch (action.type) {
        case LOIGN_ACTIONS.LOGIN_CHANGE_EMAIL:
            return { ...state, form: { ...state.form, email: { ...state.form.email, value: action.email } } }
        case LOIGN_ACTIONS.LOGIN_CHANGE_PASSWORD:
            return { ...state, form: { ...state.form, password: { ...state.form.password, value: action.password } } }
        case LOIGN_ACTIONS.LOGIN_STARTED:
            return { ...state, loginStarted: new Date() }
        case LOIGN_ACTIONS.LOGIN_OK:
            return { ...state, loginStarted: null }
        case LOIGN_ACTIONS.LOGIN_FAILED:
            return { ...state, loginStarted: null }
        default:
            return state
    }
}
