import { ChangeEvent } from 'react'
import './email.field.scss'

interface EmailFieldProps {
    field: {
        disabled?: boolean
        error: string
        name?: string
        required?: boolean
        value: string
    }
    onChange: (event: ChangeEvent<HTMLInputElement>) => void
    placeholder?: string
}

export const EmailField = (props: EmailFieldProps) => {

    return (
        <div className={`field${props.field?.error.length > 0 ? ` field--error` : ``} field--email`}>
            <input onChange={props.onChange} disabled={props.field?.disabled === true} id={props.field?.name} name={props.field?.name} placeholder={props.placeholder} required={props.field?.required !== false} type="email" value={props.field?.value} />
        </div>
    )

}
