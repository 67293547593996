export enum Language {
    HUNGARIAN = 1,
    ENGLISH = 2,
    ROMANIAN = 3,
    CROATIAN = 4,
    CZECH = 5,
    SLOVENIAN = 6,
    SLOVAK = 7,
}

export namespace Language {
    export function toString(language: Language) {
        switch (language) {
            case Language.HUNGARIAN:
                return `hu`
            case Language.ENGLISH:
                return `en`
            case Language.ROMANIAN:
                return `ro`
            case Language.CROATIAN:
                return `hr`
            case Language.CZECH:
                return `cs`
            case Language.SLOVENIAN:
                return `sl`
            case Language.SLOVAK:
                return `sk`
            default:
                return `hu`
        }
    }

    export function fromString(language: string): Language {
        switch (language) {
            case `hu`:
                return Language.HUNGARIAN
            case `en`:
                return Language.ENGLISH
            case `ro`:
                return Language.ROMANIAN
            case `hr`:
                return Language.CROATIAN
            case `cs`:
                return Language.CZECH
            case `sl`:
                return Language.SLOVENIAN
            case `sk`:
                return Language.SLOVAK
            default:
                return Language.HUNGARIAN
        }
    }

    export function fromLanguageCode(code: string) {
        if (code.includes('hu')) {
            return `hu`
        }

        if (code.includes('en')) {
            return `en`
        }

        if (code.includes('ro')) {
            return `ro`
        }

        if (code.includes('hr')) {
            return `hr`
        }

        if (code.includes('cs')) {
            return `cs`
        }

        if (code.includes('sl')) {
            return `sl`
        }

        if (code.includes('sk')) {
            return `sk`
        }

        return `hu`
    }

    export function fromLanguageCodeToLabel(code: string) {
        if (code.includes('hu')) {
            return `language_hungarian_title`
        }

        if (code.includes('en')) {
            return `language_english_title`
        }

        if (code.includes('ro')) {
            return `language_romanian_title`
        }

        if (code.includes('hr')) {
            return `language_croatian_title`
        }

        if (code.includes('cs')) {
            return `language_czech_title`
        }

        if (code.includes('sl')) {
            return `language_slovenian_title`
        }

        if (code.includes('sk')) {
            return `language_slovak_title`
        }

        return `language_hungarian_title`
    }
}
