import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { ErrorDTO, UserDTO } from '../../dto'
import { store } from '../../store'
import { BASE_ACTIONS, BASE_METHODS } from '../base/base.constants'
import { CREATE_USER_ACTIONS } from './users.constants'
import { createCall } from '../base'
import { getAllUser } from './get-all-user.action'
import { Language } from '../../enum/language.enum'
import { UserType } from '../../enum/user-type.enum'

export const createUser = async () => {
    store.dispatch({ type: CREATE_USER_ACTIONS.CREATE_USER_STARTED })

    const userId = store.getState().users.createUserForm.userId

    const body = JSON.stringify({
        role: store.getState().users.createUserForm.role,
        email: store.getState().users.createUserForm.email.value,
        firstName: store.getState().users.createUserForm.firstName.value,
        lastName: store.getState().users.createUserForm.lastName.value,
        dealershipId: store.getState().users.createUserForm.role !== UserType.REVIEWER ? store.getState().users.createUserForm.dealership : null,
        language: store.getState().users.createUserForm.language != null ? Language.toString(store.getState().users.createUserForm.language ?? Language.HUNGARIAN) : Language.toString(Language.HUNGARIAN), //TODO dealership language ha van
        termsAccepted: userId != null ? true : undefined,
    })

    const promise = createCall(`users` + (userId != null ? `/` + userId : ''), true, userId != null ? BASE_METHODS.PATCH : BASE_METHODS.POST, body)
    promise
        .then(async (response) => {
            let body: UserDTO

            try {
                body = plainToClass(UserDTO, JSON.parse(await response.text()))
            } catch (exception) {
                store.dispatch({ type: BASE_ACTIONS.ERROR_INVALID_JSON })
                return
            }

            if ((await validate(body)).length > 0) {
                store.dispatch({ type: BASE_ACTIONS.ERROR_INVALID_RESPONSE })
                return
            }

            store.dispatch({ type: CREATE_USER_ACTIONS.CREATE_USER_OK, user: body.user })

            store.dispatch({ type: BASE_ACTIONS.CLEAR_ERROR })

            getAllUser()
        })
        .catch(async (response) => {
            let error: ErrorDTO

            try {
                error = plainToClass(ErrorDTO, JSON.parse(await response.text()))
            } catch (exception) {
                //store.dispatch({ type: response })
                return
            }

            store.dispatch({ type: CREATE_USER_ACTIONS.CREATE_USER_FAILED })

            store.dispatch({ type: BASE_ACTIONS.ERROR_MESSAGE, message: error.message, code: error.code })
        })
}
