import { MouseEvent, useEffect, useRef, useState } from 'react'
import { Cover, Vector } from '../../../../../entity'
import './draw-freehand.scss'

interface DrawFreehandProps {
    width: number
    height: number
    widthRaito: number
    onDrawEnd: CallableFunction
}

export const DrawFreehand = (props: DrawFreehandProps) => {
    const canvasRef = useRef<HTMLCanvasElement | null>(null)
    const contextRef = useRef<CanvasRenderingContext2D | null>(null)

    const [isDrawing, setIsDrawing] = useState(false)
    const [points, setPoints] = useState<Vector[]>([])

    useEffect(() => {
        if (!canvasRef.current) {
            return
        }
        const canvas = canvasRef.current
        canvas.width = props.width
        canvas.height = props.height

        canvas.style.width = props.width + 'px'
        canvas.style.height = props.height + 'px'

        const context = canvas!!.getContext('2d')
        context!!.lineCap = 'round'
        context!!.lineJoin = 'round'
        context!!.strokeStyle = 'white'
        context!!.lineWidth = 20

        contextRef.current = context
    }, [])

    const onStart = (event: MouseEvent) => {
        const { offsetX, offsetY } = event.nativeEvent

        if (canvasRef.current) {
            canvasRef.current.style.width = props.width + 'px'
            canvasRef.current.style.height = props.height + 'px'
        }

        if (contextRef.current) {
            contextRef.current.lineCap = 'round'
            contextRef.current.lineJoin = 'round'
            contextRef.current.strokeStyle = 'white'
            contextRef.current.fillStyle = '#fff'
            contextRef.current.lineWidth = 20

            contextRef.current.beginPath()

            contextRef.current.moveTo(offsetX, offsetY)
            contextRef.current.lineTo(offsetX, offsetY)
            contextRef.current.stroke()

            const point = new Vector()
            point.x = offsetX / props.widthRaito
            point.y = offsetY / props.widthRaito
            point.flags = '[]'

            setPoints((prevPoints) => [...prevPoints, point])
        }
        setIsDrawing(true)

        event.preventDefault()
    }

    const onDraw = (event: MouseEvent) => {
        if (!isDrawing) {
            return
        }

        const { offsetX, offsetY } = event.nativeEvent

        if (contextRef.current) {
            contextRef.current.lineCap = 'round'
            contextRef.current.lineJoin = 'round'
            contextRef.current.strokeStyle = 'white'
            contextRef.current.fillStyle = '#fff'
            contextRef.current.lineWidth = 20

            contextRef.current.lineTo(offsetX, offsetY)
            contextRef.current.stroke()

            const point = new Vector()
            point.x = offsetX / props.widthRaito
            point.y = offsetY / props.widthRaito
            point.flags = '[]'

            setPoints((prevPoints) => [...prevPoints, point])
        }

        event.preventDefault()
    }

    const onStop = async () => {
        if (contextRef.current) {
            contextRef.current.closePath()

            if (isDrawing) {
                const cover = new Cover()
                cover.id = Math.random()
                cover.type = 3

                const position = new Vector()
                position.x = 0
                position.y = 0
                cover.position = position

                cover.width = 0
                cover.height = 0
                cover.radius = 0
                cover.rotation = 0

                cover.points = points

                const point = new Vector()
                point.x = 0
                point.y = 0
                point.flags = '[SPACER]'

                cover.points.push(point)

                cover.created = 0
                cover.updated = 0

                props.onDrawEnd(cover)

                setPoints((prevPoints) => [])

                contextRef.current.clearRect(0, 0, props.width, props.height)
            }
        }
        setIsDrawing(false)
    }

    const saveImageToLocal = (nativeEvent: MouseEvent) => {
        //     let link = event.currentTarget
        //     link.setAttribute('download', 'canvas.png')
        //     if (canvasRef.current) {
        //         let image = canvasRef.current.toDataURL('image/png')
        //         link.setAttribute('href', image)
        //     }
    }

    return (
        <div className="draw-freehand">
            <canvas className="canvas" ref={canvasRef} onMouseDown={onStart} onMouseMove={onDraw} onMouseUp={onStop} onMouseLeave={onStop} height={props.height} width={props.width}></canvas>
        </div>
    )
}
