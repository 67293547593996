import { Link, useLocation } from 'react-router-dom'
import { MenuGroup } from './menu-group'
import { useTranslation } from 'react-i18next'
import './menu-element.scss'
import { useDispatch } from 'react-redux'
import { GET_ALL_USER_ACTIONS } from '../../action/users/users.constants'

interface MenuElementProps {
    menuElement: any
    onMobileMenuToggle?: (open: boolean) => void
}

export const MenuElement = (props: MenuElementProps) => {
    const location = useLocation()
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const open = props.menuElement.path !== '/' && location.pathname.startsWith(props.menuElement.path)

    const isMenuActive = () => {
        if (props.menuElement.path === location.pathname) {
            return true
        }

        var isActive = false
        props.menuElement.includes.map((str: string) => {
            if (location.pathname.includes(str)) {
                isActive = true
            }
        })

        return isActive
    }

    const className = `${isMenuActive() ? `active` : ``}${open ? ` open` : ``}${props.menuElement.path !== location.pathname && open ? ` highlighted` : ``}`

    const onMobileMenuToggle = (open: boolean) => {
        if (props.onMobileMenuToggle) props.onMobileMenuToggle(open)
    }

    return (
        <li className="menu__menu-element">
            <Link
                className={className}
                to={props.menuElement.path}
                onClick={() => {
                    dispatch({ type: GET_ALL_USER_ACTIONS.GET_ALL_USER_SEARCH, search: '' })

                    onMobileMenuToggle(false)
                }}
            >
                <img alt={props.menuElement.caption} src={'/image/' + props.menuElement.icon} />

                <span>{t(props.menuElement.caption)}</span>
            </Link>
            {props.menuElement.children ? <MenuGroup menuGroup={props.menuElement.children} open={open} /> : null}
        </li>
    )
}
