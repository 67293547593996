import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { ErrorDTO, UserDTO } from '../../dto'
import { store } from '../../store'
import { BASE_ACTIONS, BASE_METHODS } from '../base/base.constants'
import { createCall } from '../base'
import { getAllUser } from './get-all-user.action'
import { UPDATE_USER_STATUS_ACTIONS } from './users.constants'
import { Status } from '../../enum/status.enum'

export const updateUserStatus = async (id: number, status: Status) => {
    store.dispatch({ type: UPDATE_USER_STATUS_ACTIONS.UPDATE_USER_STATUS_STARTED })

    const promise = createCall(`users/${id}/status?status=${status}`, true, BASE_METHODS.PATCH)
    promise
        .then(async (response) => {
            let body: UserDTO

            try {
                body = plainToClass(UserDTO, JSON.parse(await response.text()))
            } catch (exception) {
                store.dispatch({ type: BASE_ACTIONS.ERROR_INVALID_JSON })
                return
            }

            if ((await validate(body)).length > 0) {
                store.dispatch({ type: BASE_ACTIONS.ERROR_INVALID_RESPONSE })
                return
            }

            store.dispatch({ type: UPDATE_USER_STATUS_ACTIONS.UPDATE_USER_STATUS_OK, user: body.user })

            store.dispatch({ type: BASE_ACTIONS.CLEAR_ERROR })

            getAllUser()
        })
        .catch(async (response) => {
            let error: ErrorDTO

            try {
                error = plainToClass(ErrorDTO, JSON.parse(await response.text()))
            } catch (exception) {
                //store.dispatch({ type: response })
                return
            }

            store.dispatch({ type: UPDATE_USER_STATUS_ACTIONS.UPDATE_USER_STATUS_FAILED })

            store.dispatch({ type: BASE_ACTIONS.ERROR_MESSAGE, message: error.message, code: error.code })
        })
}
