import { ChangeEvent, createRef, FormEvent, MouseEvent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { login } from '../../action'
import { LoadingIcon } from '../../component/icon'
import { EmailField, PasswordField } from '../../component/field'
import { State } from '../../state'
import { LOIGN_ACTIONS } from '../../action/users/users.constants'
import { useTranslation } from 'react-i18next'
import { validateEmail, validatePassword } from '../../helper/validate.helper'
import './login.page.scss'

export const LoginPage = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { t } = useTranslation()

    const authenticatedUser = useSelector((state: State) => state.session.authenticatedUser)
    const { form, loginStarted } = useSelector((state: State) => state.login)
    const formElement = createRef<HTMLFormElement>()

    const [emailError, setEmailError] = useState('')
    const [passwordError, setPasswordError] = useState('')

    if (authenticatedUser === undefined) {
        return null
    }

    if (authenticatedUser !== null) {
        let url = new URL(window.location.href).searchParams.get('redirect') ?? '/'

        if (url.includes('/login')) {
            url = '/'
        }
        if (!authenticatedUser.termsAccepted) {
            url = '/terms'
        }

        return <Navigate to={url} />
    }

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()
        if (!validateFileds()) {
            return
        }

        if (!formElement.current?.reportValidity()) {
            return
        }

        await login()
    }

    const validateFileds = () => {
        setEmailError('')
        setPasswordError('')

        const emailValidation = validateEmail(form.email.value)
        if (emailValidation != null) {
            setEmailError(t(emailValidation))
            return false
        }

        const passwordValidation = validatePassword(form.password.value, true)
        if (passwordValidation != null) {
            setPasswordError(t(passwordValidation))
            return false
        }
        return true
    }

    const onEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        dispatch({ type: LOIGN_ACTIONS.LOGIN_CHANGE_EMAIL, email: event.target.value })
    }

    const onPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        dispatch({ type: LOIGN_ACTIONS.LOGIN_CHANGE_PASSWORD, password: event.target.value })
    }

    return (
        <main className="page--login">
            <form onSubmit={onSubmit} className="login-form" id="login-form" ref={formElement}>
                <h2>{t('login_title')}</h2>
                <EmailField field={form.email} onChange={onEmailChange} placeholder={t('login_email_placeholder')} />
                <p className={`field-message${emailError.length > 0 ? ` field-message--error` : ``}`}>{emailError.length > 0 ? emailError : t('login_email_hint')}</p>

                <PasswordField field={form.password} onChange={onPasswordChange} placeholder={t('login_password_placeholder')} />
                <p className={`field-message${passwordError.length > 0 ? ` field-message--error` : ``}`}>{passwordError.length > 0 ? passwordError : t('login_password_hint')}</p>

                <div className="login-form__links">
                    <Link className={`login-form__links__link`} to="/forgot-password">
                        {t('login_forgot_password_button')}
                    </Link>
                </div>

                <footer>
                    <button className="button" disabled={loginStarted !== null} form="login-form" type="submit">
                        {loginStarted !== null ? <LoadingIcon /> : t('login_login_button')}
                    </button>
                </footer>
            </form>

            <div className="privacy-div">
                <button className="privacy-button" onClick={() => navigate('/privacy-policy')}>
                    {t('login_privacy_policy_button')}
                </button>

                <button className="privacy-button" onClick={() => navigate('/terms-of-use')}>
                    {t('terms_title')}
                </button>
            </div>
        </main>
    )
}
