export const DELETE_PHOTO_ACTIONS = {
    DELETE_PHOTO_ACTIONS_STARTED: 'DELETE_PHOTO_ACTIONS_STARTED',
    DELETE_PHOTO_ACTIONS_OK: 'DELETE_PHOTO_ACTIONS_OK',
    DELETE_PHOTO_ACTIONS_FAILED: 'DELETE_PHOTO_ACTIONS_FAILED',
}

export const GET_PHOTO_ACTIONS = {
    GET_PHOTO_STARTED: 'GET_PHOTO_STARTED',
    GET_PHOTO_OK: 'GET_PHOTO_OK',
    GET_PHOTO_FAILED: 'GET_PHOTO_FAILED',
}

export const PHOTO_EDITOR_ACTIONS = {
    INITIALIZE: 'INITIALIZE',
    CLEAR: 'CLEAR',
    IS_TOOLS_ENABLED: 'IS_TOOLS_ENABLED',
    SELECTED_TOOL: 'SELECTED_TOOL',
    ACCEPT_COVER: 'ACCEPT_COVER',
    ADD_COVER: 'ADD_COVER',
    REMOVE_COVER: 'REMOVE_COVER',
    DELETE_ALL_COVER: 'DELETE_ALL_COVER',
}

export const UPLOAD_PHOTOS_DATA_ACTIONS = {
    UPLOAD_PHOTOS_DATA_ACTIONS_STARTED: 'UPLOAD_PHOTOS_DATA_ACTIONS_STARTED',
    UPLOAD_PHOTOS_DATA_ACTIONS_OK: 'UPLOAD_PHOTOS_DATA_ACTIONS_OK',
    UPLOAD_PHOTOS_DATA_ACTIONS_FAILED: 'UPLOAD_PHOTOS_DATA_ACTIONS_FAILED',
}
