import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { ErrorDTO, LoginDTO } from '../../dto'
import { store } from '../../store'
import { BASE_ACTIONS, BASE_METHODS } from '../base/base.constants'
import { LOIGN_ACTIONS } from './users.constants'
import { createCall } from '../base'

export const login = async () => {
    store.dispatch({ type: LOIGN_ACTIONS.LOGIN_STARTED })

    const body = JSON.stringify({
        email: store.getState().login.form.email.value,
        password: store.getState().login.form.password.value,
    })

    const promise = createCall(`users/login`, false, BASE_METHODS.POST, body)
    promise
        .then(async (response) => {
            let body: LoginDTO

            try {
                body = plainToClass(LoginDTO, JSON.parse(await response.text()))
            } catch (exception) {
                store.dispatch({ type: BASE_ACTIONS.ERROR_INVALID_JSON })
                return
            }

            if ((await validate(body)).length > 0) {
                store.dispatch({ type: BASE_ACTIONS.ERROR_INVALID_RESPONSE })
                return
            }

            store.dispatch({ type: LOIGN_ACTIONS.LOGIN_OK, user: body.user, token: body.session.token })

            store.dispatch({ type: BASE_ACTIONS.CLEAR_ERROR })
        })
        .catch(async (response) => {
            let error: ErrorDTO

            try {
                error = plainToClass(ErrorDTO, JSON.parse(await response.text()))
            } catch (exception) {
                //store.dispatch({ type: response })
                return
            }

            store.dispatch({ type: LOIGN_ACTIONS.LOGIN_FAILED })

            store.dispatch({ type: BASE_ACTIONS.ERROR_MESSAGE, message: error.message, code: error.code })
        })
}
