import { Expose, Type } from 'class-transformer'
import { IsBoolean, IsDefined, IsIn, IsNumber, IsOptional, IsString, ValidateIf, ValidateNested } from 'class-validator'
import { Dealership } from './dealership.entity'
import { UserType } from '../enum/user-type.enum'

export class User {
    @Expose()
    @IsDefined()
    @IsNumber()
    id!: number

    @Expose()
    @IsDefined()
    @IsString()
    firstName!: string

    @Expose()
    @IsDefined()
    @IsString()
    lastName!: string

    @Expose()
    @IsDefined()
    @IsString()
    @ValidateIf((object, value) => value !== null)
    email!: string | null

    @Expose()
    @IsDefined()
    @IsString()
    language!: string

    @Expose()
    @IsDefined()
    @IsBoolean()
    termsAccepted!: boolean

    @Expose()
    @IsDefined()
    @IsNumber()
    @IsIn(
        Object.keys(UserType)
            .map((key: string) => parseInt(key))
            .filter((key: number) => !isNaN(key))
    )
    role!: number

    @Expose()
    @IsDefined()
    @IsNumber()
    status!: number

    @IsOptional()
    @IsDefined()
    @ValidateIf((object, value) => value !== null)
    dealership?: Dealership | null

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== null)
    deactivatedDate!: number | null

    @Expose()
    @IsDefined()
    @IsNumber()
    created!: number

    @IsOptional()
    @IsDefined()
    @IsNumber()
    updated!: number
}
