import { Expose } from 'class-transformer'
import { IsDefined, IsNumber, IsString } from 'class-validator'

export class ErrorDTO {
    @Expose()
    @IsDefined()
    @IsNumber()
    code!: number

    @Expose()
    @IsDefined()
    @IsString()
    message!: string
}
