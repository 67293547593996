import { createBrowserRouter } from 'react-router-dom'
import { RequireAuth } from '../component'
import { DefaultLayout, LoginLayout } from '../layout'
import { userManagemenetPageLoader, warrantiesPageLoader, warrantyDetailPageLoader, warrantyGroupsPageLoader, dealershipsPageLoader, warrantyPhotoPageLoader } from '../loader'
import { ForgotPasswordPage, LoginPage, TermsPage, ResetPasswordPage } from '../page/users'
import { WarrantiesPage, WarrantyGroupPage, WarrantyDetailPage } from '../page/warranties'
import { UserManagementPage } from '../page/user-management'
import { DealershipsPage } from '../page/dealerships'
import { ProfilPage } from '../page/profile'
import { WarrantyPhotoPage } from '../page/warranties/warranty-photo.page'
import { DataDeletionPage } from '../page/data-deletion'
import { PrivacyPage } from '../page/privacy'
import { TermsOfUsePage } from '../page/terms-of-use'

export const router = createBrowserRouter([
    {
        children: [
            {
                element: <WarrantyGroupPage />,
                loader: warrantyGroupsPageLoader,
                path: '',
            },
            {
                element: <WarrantiesPage />,
                loader: warrantiesPageLoader,
                path: 'warranties/:vin',
            },
            {
                element: <WarrantyDetailPage />,
                loader: warrantyDetailPageLoader,
                path: 'warranty/:id',
            },
            {
                element: <UserManagementPage />,
                loader: userManagemenetPageLoader,
                path: 'users',
            },
            {
                element: <UserManagementPage />,
                loader: userManagemenetPageLoader,
                path: 'users/:role',
            },
            {
                element: <UserManagementPage />,
                loader: userManagemenetPageLoader,
                path: 'users/:role',
            },
            {
                element: <UserManagementPage />,
                loader: userManagemenetPageLoader,
                path: 'users/:role',
            },
            {
                element: <DealershipsPage />,
                loader: dealershipsPageLoader,
                path: 'dealerships',
            },
            {
                element: <ProfilPage />,
                path: 'profile',
            },
        ],
        element: (
            <RequireAuth>
                <DefaultLayout />
            </RequireAuth>
        ),
        errorElement: null,
        path: '/',
    },
    {
        children: [
            {
                element: <LoginPage />,
                path: `login`,
            },
            {
                element: <ForgotPasswordPage />,
                path: `forgot-password`,
            },
            {
                element: <TermsPage />,
                path: `terms`,
            },
            {
                element: <PrivacyPage />,
                path: `privacy-policy`,
            },
            {
                element: <TermsOfUsePage />,
                path: `terms-of-use`,
            },
            {
                element: <ResetPasswordPage />,
                path: `reset-password`,
            },
            {
                element: <DataDeletionPage />,
                path: `data-deletion-request`,
            },
        ],
        element: <LoginLayout />,
        errorElement: null,
        path: '/',
    },
    {
        element: <div />,
        errorElement: null,
        path: '/forgot-password',
    },
    {
        element: <WarrantyPhotoPage />,
        loader: warrantyPhotoPageLoader,
        path: 'photo/:id',
    },
])
