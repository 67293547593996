import { Expose, Type } from 'class-transformer'
import { ValidateNested } from 'class-validator'
import { Dealership } from '../../entity'

export class AllDealershipsDTO {
    @Expose()
    @ValidateNested()
    @Type(() => Dealership)
    dealerships!: Dealership[]
}
