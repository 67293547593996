import './loading.icon.scss'

export const LoadingIcon = () => {
    return (
        <div className="icon icon--loading">
            <div>.</div>
            <div>.</div>
            <div>.</div>
        </div>
    )
}
