import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import './terms-of-use.page.scss'
import { Language } from '../../enum/language.enum'

export const TermsOfUsePage = () => {
    const { t, i18n } = useTranslation()

    return (
        <main className="page--terms-of-use">
            <div className="terms-of-use-div">
                <div className="title-container">
                    <NavLink to="/login">
                        <button className="button-back">
                            <img alt="Vissza gomb" className="back" src="/image/ic_arrow_left.svg" />
                        </button>
                    </NavLink>

                    <h2>{t('terms_title')}</h2>
                </div>

                <iframe className="terms-of-use-frame" src={`terms/terms_${Language.fromLanguageCode(i18n.language)}.html`}></iframe>
            </div>
        </main>
    )
}
