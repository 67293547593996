import { CREATE_WARRANTY_ACTIONS, GET_WARRANTIES_ACTIONS } from '../action/warranties/warranties.constants'
import { WarrantiesState } from '../state/warranties.state'

const initialWarrantiesState: WarrantiesState = {
    warranties: [],
    pagination: {
        page: 1,
        pageSize: 0,
        count: 0,
    },
    search: '',
    order: 'created',
    sort: 'DESC',
    warrantiesNotFound: false,
    form: {
        vinNumber: {
            error: '',
            value: '',
        },
        vinNumberFirst: {
            error: '',
            value: '',
        },
        vinNumberSecound: {
            error: '',
            value: '',
        },
        segment: 0,
    },
}

export const warrantiesReducer = (state: WarrantiesState = initialWarrantiesState, action: any): WarrantiesState => {
    switch (action.type) {
        case GET_WARRANTIES_ACTIONS.GET_WARRANTIES_OK:
            return { ...state, warranties: action.warranties, pagination: { ...state.pagination, page: action.page, pageSize: action.pageSize, count: action.count }, warrantiesNotFound: false }
        case GET_WARRANTIES_ACTIONS.GET_WARRANTIES_CHANGE_PAGE:
            return { ...state, pagination: { ...state.pagination, page: action.page } }
        case GET_WARRANTIES_ACTIONS.GET_WARRANTIES_CHANGE_ORDER:
            return { ...state, order: action.orderBy, sort: action.sort }
        case GET_WARRANTIES_ACTIONS.GET_WARRANTIES_SEARCH:
            return { ...state, pagination: { ...state.pagination, page: 1 }, search: action.search }
        case GET_WARRANTIES_ACTIONS.GET_WARRANTIES_FAILED:
            return { ...state }
        case GET_WARRANTIES_ACTIONS.GET_WARRANTIES_WARRANTY_NOT_FOUND:
            return { ...state, warrantiesNotFound: action.value }

        case CREATE_WARRANTY_ACTIONS.CREATE_WARRANTY_CHANGE_VIN_NUMBER:
            return { ...state, form: { ...state.form, vinNumber: { ...state.form.vinNumber, value: action.vinNumber } } }
        case CREATE_WARRANTY_ACTIONS.CREATE_WARRANTY_CHANGE_VIN_NUMBER_FIRST:
            return { ...state, form: { ...state.form, vinNumberFirst: { ...state.form.vinNumberFirst, value: action.vinNumber } } }
        case CREATE_WARRANTY_ACTIONS.CREATE_WARRANTY_CHANGE_VIN_NUMBER_SECOUND:
            return { ...state, form: { ...state.form, vinNumberSecound: { ...state.form.vinNumberSecound, value: action.vinNumber } } }
        case CREATE_WARRANTY_ACTIONS.CREATE_WARRANTY_CHANGE_SEGMENT:
            return { ...state, form: { ...state.form, segment: action.segment } }
        case CREATE_WARRANTY_ACTIONS.CREATE_WARRANTY_CLEAR:
            return { ...state, form: { ...state.form, vinNumber: { ...state.form.vinNumber, value: '' }, vinNumberFirst: { ...state.form.vinNumberFirst, value: '' }, vinNumberSecound: { ...state.form.vinNumberSecound, value: '' }, segment: 0 } }
        case CREATE_WARRANTY_ACTIONS.CREATE_WARRANTY_OK:
            return { ...state }
        case CREATE_WARRANTY_ACTIONS.CREATE_WARRANTY_FAILED:
            return { ...state }

        default:
            return state
    }
}
