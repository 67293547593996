import { ChangeEvent } from 'react'
import './text.field.scss'

interface TextFieldProps {
    field: {
        disabled?: boolean
        error: string
        name?: string
        required?: boolean
        value: string
    }
    onChange: (event: ChangeEvent<HTMLInputElement>) => void
    placeholder?: string
    maxLength?: number
}

export const TextField = (props: TextFieldProps) => {
    return (
        <div className={`field${props.field?.error.length > 0 ? ` field--error` : ``} field--text`}>
            <input onChange={props.onChange} disabled={props.field?.disabled === true} id={props.field?.name} name={props.field?.name} placeholder={props.placeholder} maxLength={props.maxLength} required={props.field?.required !== false} type="text" value={props.field?.value} />
        </div>
    )
}
