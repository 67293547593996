import { Expose } from 'class-transformer'
import { IsDefined, IsNumber, IsOptional, IsString, ValidateIf } from 'class-validator'
import { User } from './user.entity'
import { Metadata } from './metadata.entity'
import { Warranty } from './warranty.entity'

export class Photo {
    @Expose()
    @IsDefined()
    @IsNumber()
    id!: number

    @Expose()
    @IsDefined()
    @IsString()
    url!: string

    @Expose()
    @IsDefined()
    @IsString()
    coveredUrl!: string

    @Expose()
    @IsDefined()
    @IsString()
    hash!: string

    @Expose()
    @IsDefined()
    @ValidateIf((object, value) => value !== null)
    sender?: User | null

    @Expose()
    @IsDefined()
    @ValidateIf((object, value) => value !== null)
    modifier?: User | null

    @Expose()
    @IsDefined()
    @ValidateIf((object, value) => value !== null)
    metadata?: Metadata | null

    @Expose()
    @IsDefined()
    @IsNumber()
    created!: number

    @IsOptional()
    @IsDefined()
    @IsNumber()
    updated!: number

    @IsOptional()
    @IsDefined()
    @ValidateIf((object, value) => value !== null)
    warranty?: Warranty | null
  
}
