import { ChangeEvent, KeyboardEvent, useRef } from 'react'
import './search.field.scss'

interface SearchFieldProps {

    value: string

    onChange: (event: ChangeEvent<HTMLInputElement>) => void
    placeholder?: string
    onSearch?: (e: KeyboardEvent<HTMLInputElement>) => void
}

export const SearchField = (props: SearchFieldProps) => {

    const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === `Enter` && props.onSearch) {
            props.onSearch(e)
        }
    }

    return (
        // <div className={ `field${ props.field.error.length > 0 ? ` field--error` : `` } field--search` }>
        <div className="field field--search">
            <input value={props.value}  onChange={props.onChange} placeholder={props.placeholder} required={false} type="search" onKeyDown={onKeyDown} />
        </div>
    )
}
