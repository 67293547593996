import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { GET_WARRANTY_GROUPS_ACTIONS } from '../../../action/warranties/warranties.constants'
import { getWarrantyGroups } from '../../../action/warranties'
import './warranty-groups-table-segment-filter.scss'

interface WarrantyGroupsTableSegmentFilterProps {
    segment: number | null
}

export const WarrantyGroupsTableSegmentFilter = (props: WarrantyGroupsTableSegmentFilterProps) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const onSegmentClick = async (segment: number) => {
        if (props.segment === segment) {
            dispatch({ type: GET_WARRANTY_GROUPS_ACTIONS.GET_WARRANTY_GROUPS_CHANGE_SEGMENT, segment: null })
        } else {
            dispatch({ type: GET_WARRANTY_GROUPS_ACTIONS.GET_WARRANTY_GROUPS_CHANGE_SEGMENT, segment: segment })
        }
        await getWarrantyGroups()
    }

    return (
        <div className="warranty-groups-table-segment-filter">
            {t('warranty_table_type_title')} {props.segment === null ? null : props.segment === 1 ? '(' + t('segment_automobile_title') + ')' : props.segment === 2 ? '(' + t('segment_motorcycle_title') + ')' : '(' + t('segment_marine_title') + ')'}
            <div className="segment-drop-down">
                {/* <img className="" alt="Filter ikon" src={props.segment === null ? '/image/ic_filter.svg' : '/image/ic_filter_filled.svg'} /> */}
                <div className="filter-image" />

                <div className="segment-drop-down-content">
                    <a className={props.segment == 1 ? 'selected' : ''} onClick={() => onSegmentClick(1)}>
                        {t('segment_automobile_title')}
                    </a>
                    <a className={props.segment == 2 ? 'selected' : ''} onClick={() => onSegmentClick(2)}>
                        {t('segment_motorcycle_title')}
                    </a>
                    <a className={props.segment == 3 ? 'selected' : ''} onClick={() => onSegmentClick(3)}>
                        {t('segment_marine_title')}
                    </a>
                </div>
            </div>{' '}
        </div>
    )
}
