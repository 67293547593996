import { BASE_ACTIONS } from '../action/base/base.constants'
import { handleServerErrors } from '../helper'
import { BaseState } from '../state'

const initialBaseState: BaseState = {
    errorTitle: null,
    errorMessage: null,
    errorCode: null,
}

export const baseReducer = (state: BaseState = initialBaseState, action: any): BaseState => {
    switch (action.type) {
        case BASE_ACTIONS.ERROR_NO_USER:
            return { ...state, errorTitle: 'error_default_title', errorMessage: 'error_no_user' }
        case BASE_ACTIONS.ERROR_TIMEOUT:
            return { ...state, errorTitle: 'error_default_title', errorMessage: 'error_timeout' }
        case BASE_ACTIONS.ERROR_UNAUTHORIZED:
            return { ...state, errorTitle: 'error_default_title', errorMessage: 'error_unauthorized_message' }
        case BASE_ACTIONS.ERROR_UNEXPECTED_RESPONSE:
            return { ...state, errorTitle: 'error_default_title', errorMessage: 'error_unexpected_response' }
        case BASE_ACTIONS.ERROR_INVALID_JSON:
            return { ...state, errorTitle: 'error_default_title', errorMessage: 'error_invalid_json' }
        case BASE_ACTIONS.ERROR_INVALID_RESPONSE:
            return { ...state, errorTitle: 'error_default_title', errorMessage: 'error_invalid_response' }
        case BASE_ACTIONS.ERROR_MESSAGE:
            return { ...state, errorTitle: 'error_default_title', errorMessage: action.code ? handleServerErrors(action.code) : action.message, errorCode: action.code }
        case BASE_ACTIONS.CLEAR_ERROR:
            return { ...state, errorTitle: null, errorMessage: null, errorCode: null }
        default:
            return state
    }
}
