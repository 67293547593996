import { Expose } from 'class-transformer'
import { IsDefined, IsNumber, IsOptional, IsString, ValidateIf } from 'class-validator'
import { Cover } from './cover.entity'

export class Metadata {
    @Expose()
    @IsDefined()
    @IsNumber()
    id!: number

    @Expose()
    @IsDefined()
    @IsNumber()
    version!: number

    @Expose()
    @IsDefined()
    @IsNumber()
    status!: number

    @Expose()
    @IsDefined()
    @IsNumber()
    width!: number

    @Expose()
    @IsDefined()
    @IsNumber()
    height!: number

    @Expose()
    @IsDefined()
    @IsNumber()
    lat!: number

    @Expose()
    @IsDefined()
    @IsNumber()
    lon!: number

    @Expose()
    @IsDefined()
    @IsNumber()
    mobileCreated!: number

    @Expose()
    @IsDefined()
    @ValidateIf((object, value) => value !== null)
    covers?: [Cover] | null

    @Expose()
    @IsDefined()
    @IsNumber()
    created!: number

    @IsOptional()
    @IsDefined()
    @IsNumber()
    updated!: number
}
