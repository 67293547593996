import { LoaderFunctionArgs } from 'react-router-dom'
import { GET_WARRANTY_GROUPS_ACTIONS } from '../../action/warranties/warranties.constants'
import { store } from '../../store'
import { getWarrantyGroups } from '../../action/warranties'

export const warrantyGroupsPageLoader = async (args: LoaderFunctionArgs) => {
    store.dispatch({ type: GET_WARRANTY_GROUPS_ACTIONS.GET_WARRANTY_GROUPS_SEARCH, search: '' })

    await getWarrantyGroups()

    return null
}
