import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { DealershipDTO, ErrorDTO } from '../../dto'
import { store } from '../../store'
import { BASE_ACTIONS, BASE_METHODS } from '../base/base.constants'
import { createCall } from '../base'
import { CREATE_DEALERSHIP_ACTIONS } from './dealerships.constants'
import { getDealerships } from './get-dealerships.action'
import { Language } from '../../enum/language.enum'

export const createDealership = async () => {
    store.dispatch({ type: CREATE_DEALERSHIP_ACTIONS.CREATE_DEALERSHIP_STARTED })

    const dealershipId = store.getState().dealerships.createDealershipForm.dealershipId

    const body = JSON.stringify({
        serviceName: store.getState().dealerships.createDealershipForm.name.value,
        serviceCode: store.getState().dealerships.createDealershipForm.code.value,
        countryId: store.getState().dealerships.createDealershipForm.countryId,
        language: Language.toString(store.getState().dealerships.createDealershipForm.language ?? Language.HUNGARIAN),
        segments: store.getState().dealerships.createDealershipForm.segments,
        status: store.getState().dealerships.createDealershipForm.status,
    })

    const promise = createCall(`dealerships` + (dealershipId != null ? `/` + dealershipId : ''), true, dealershipId != null ? BASE_METHODS.PATCH : BASE_METHODS.POST, body)
    promise
        .then(async (response) => {
            let body: DealershipDTO

            try {
                body = plainToClass(DealershipDTO, JSON.parse(await response.text()))
            } catch (exception) {
                store.dispatch({ type: BASE_ACTIONS.ERROR_INVALID_JSON })
                return
            }

            if ((await validate(body)).length > 0) {
                store.dispatch({ type: BASE_ACTIONS.ERROR_INVALID_RESPONSE })
                return
            }

            store.dispatch({ type: CREATE_DEALERSHIP_ACTIONS.CREATE_DEALERSHIP_OK, dealership: body.dealership })

            store.dispatch({ type: BASE_ACTIONS.CLEAR_ERROR })

            getDealerships()
        })
        .catch(async (response) => {
            let error: ErrorDTO

            try {
                error = plainToClass(ErrorDTO, JSON.parse(await response.text()))
            } catch (exception) {
                //store.dispatch({ type: response })
                return
            }

            store.dispatch({ type: CREATE_DEALERSHIP_ACTIONS.CREATE_DEALERSHIP_FAILED })

            store.dispatch({ type: BASE_ACTIONS.ERROR_MESSAGE, message: error.message, code: error.code })
        })
}
