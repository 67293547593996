/**
 * Gets or sets (if the value parameter is provided) the value of a cookie with the given name
 **/
export const cookie = (name: string, value: string | undefined = undefined): string | null => {
    if (value !== undefined) {
        document.cookie = `${name}=${value}`
    }

    let cookie = null
    document.cookie.split(';').forEach((c: string) => {
        const [key, value] = c.split('=')
        if (key.trim() === name && value !== undefined) {
            cookie = value
        }
    })

    return cookie
}
