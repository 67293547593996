import { CREATE_DEALERSHIP_ACTIONS, GET_COUNTRIES_ACTIONS, GET_DEALERSHIPS_ACTIONS } from '../action/dealerships/dealerships.constants'
import { Status } from '../enum/status.enum'
import { DealershipsState } from '../state'

const initialDealershipsState: DealershipsState = {
    dealerships: [],
    pagination: {
        page: 1,
        pageSize: 0,
        count: 0,
    },
    search: '',
    order: 'dealership.serviceCode',
    sort: 'DESC',

    createDealershipForm: {
        dealershipId: null,

        name: {
            error: '',
            value: '',
        },
        code: {
            error: '',
            value: '',
        },

        status: Status.ACTIVE,
        countryName: null,
        countryId: null,

        segments: [],
        language: null,
    },
    createDealershipStarted: undefined,

    countries: [],
    getCoutriesStarted: undefined,
}

export const dealershipsReducer = (state: DealershipsState = initialDealershipsState, action: any): DealershipsState => {
    switch (action.type) {
        case GET_DEALERSHIPS_ACTIONS.GET_DEALERSHIPS_OK:
            return { ...state, dealerships: action.dealerships, pagination: { ...state.pagination, page: action.page, pageSize: action.pageSize, count: action.count } }
        case GET_DEALERSHIPS_ACTIONS.GET_DEALERSHIPS_CHANGE_PAGE:
            return { ...state, pagination: { ...state.pagination, page: action.page } }
        case GET_DEALERSHIPS_ACTIONS.GET_DEALERSHIPS_CHANGE_ORDER:
            return { ...state, order: action.orderBy, sort: action.sort }
        case GET_DEALERSHIPS_ACTIONS.GET_DEALERSHIPS_SEARCH:
            return { ...state, pagination: { ...state.pagination, page: 1 }, search: action.search }
        case GET_DEALERSHIPS_ACTIONS.GET_DEALERSHIPS_FAILED:
            return { ...state }

        case CREATE_DEALERSHIP_ACTIONS.CREATE_DEALERSHIP_CHANGE_DEALERSHIP_ID:
            return { ...state, createDealershipForm: { ...state.createDealershipForm, dealershipId: action.dealershipId } }
        case CREATE_DEALERSHIP_ACTIONS.CREATE_DEALERSHIP_CHANGE_NAME:
            return { ...state, createDealershipForm: { ...state.createDealershipForm, name: { ...state.createDealershipForm.name, value: action.name } } }
        case CREATE_DEALERSHIP_ACTIONS.CREATE_DEALERSHIP_CHANGE_CODE:
            return { ...state, createDealershipForm: { ...state.createDealershipForm, code: { ...state.createDealershipForm.code, value: action.code } } }
        case CREATE_DEALERSHIP_ACTIONS.CREATE_DEALERSHIP_CHANGE_COUNTRY:
            return { ...state, createDealershipForm: { ...state.createDealershipForm, countryName: action.countryName, countryId: action.countryId } }
        case CREATE_DEALERSHIP_ACTIONS.CREATE_DEALERSHIP_CHANGE_LANGUAGE:
            return { ...state, createDealershipForm: { ...state.createDealershipForm, language: action.language } }
        case CREATE_DEALERSHIP_ACTIONS.CREATE_DEALERSHIP_CHANGE_STATUS:
            return { ...state, createDealershipForm: { ...state.createDealershipForm, status: action.status } }
        case CREATE_DEALERSHIP_ACTIONS.CREATE_DEALERSHIP_CHANGE_SEGMENT:
            const segmentArray = state.createDealershipForm.segments

            if (
                segmentArray.find((segment) => {
                    return segment === action.segments
                }) === undefined
            ) {
                segmentArray.push(action.segments)
            } else {
                const indexOfObject = segmentArray.findIndex((segment) => {
                    return segment === action.segments
                })

                if (indexOfObject !== -1) {
                    segmentArray.splice(indexOfObject, 1)
                }
            }

            return { ...state, createDealershipForm: { ...state.createDealershipForm, segments: segmentArray } }
        case CREATE_DEALERSHIP_ACTIONS.CREATE_DEALERSHIP_CHANGE_SEGMENTS:
            return { ...state, createDealershipForm: { ...state.createDealershipForm, segments: action.segments } }
        case CREATE_DEALERSHIP_ACTIONS.CREATE_DEALERSHIP_CLEAR:
            return { ...state, createDealershipForm: { ...state.createDealershipForm, name: { ...state.createDealershipForm.name, value: '' }, code: { ...state.createDealershipForm.code, value: '' }, status: Status.ACTIVE, language: null, dealershipId: null, countryId: null, countryName: null, segments: [] } }
        case CREATE_DEALERSHIP_ACTIONS.CREATE_DEALERSHIP_STARTED:
            return { ...state, createDealershipStarted: new Date() }
        case CREATE_DEALERSHIP_ACTIONS.CREATE_DEALERSHIP_OK:
            return { ...state, createDealershipStarted: null }
        case CREATE_DEALERSHIP_ACTIONS.CREATE_DEALERSHIP_FAILED:
            return { ...state, createDealershipStarted: undefined }

        case GET_COUNTRIES_ACTIONS.GET_COUNTRIES_ACTIONS_STARTED:
            return { ...state, getCoutriesStarted: new Date() }
        case GET_COUNTRIES_ACTIONS.GET_COUNTRIES_ACTIONS_OK:
            return { ...state, getCoutriesStarted: null, countries: action.countries }
        case GET_COUNTRIES_ACTIONS.GET_COUNTRIES_ACTIONS_FAILED:
            return { ...state, getCoutriesStarted: undefined }

        default:
            return state
    }
}
