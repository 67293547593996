import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { ErrorDTO } from '../../dto'
import { store } from '../../store'
import { BASE_ACTIONS, BASE_METHODS } from '../base/base.constants'
import { createCall } from '../base'
import { GET_WARRANTY_GROUPS_ACTIONS } from './warranties.constants'
import { WarrantyGrpoupsDTO } from '../../dto/warranties'

export const getWarrantyGroups = async () => {
    store.dispatch({ type: GET_WARRANTY_GROUPS_ACTIONS.GET_WARRANTY_GROUPS_STARTED })

    const page = store.getState().warrantyGroup.pagination.page
    const search = store.getState().warrantyGroup.search
    const orderBy = store.getState().warrantyGroup.order
    const sort = store.getState().warrantyGroup.sort
    const segment = store.getState().warrantyGroup.segment

    const promise = createCall(`warranties?page=${page}&pageSize=${15}${search !== '' ? `&search=${search}` : ``}${segment ? `&segments=${segment}` : ``}&orderBy=${orderBy}&sort=${sort}`, true, BASE_METHODS.GET)
    promise
        .then(async (response) => {
            let body: WarrantyGrpoupsDTO

            try {
                body = plainToClass(WarrantyGrpoupsDTO, JSON.parse(await response.text()))
            } catch (exception) {
                store.dispatch({ type: BASE_ACTIONS.ERROR_INVALID_JSON })
                return
            }

            if ((await validate(body)).length > 0) {
                store.dispatch({ type: BASE_ACTIONS.ERROR_INVALID_RESPONSE })
                return
            }

            store.dispatch({ type: GET_WARRANTY_GROUPS_ACTIONS.GET_WARRANTY_GROUPS_OK, warranties: body.data, page: body.page, pageSize: body.pageSize, count: body.count })

            store.dispatch({ type: BASE_ACTIONS.CLEAR_ERROR })
        })
        .catch(async (response) => {
            let error: ErrorDTO

            try {
                error = plainToClass(ErrorDTO, JSON.parse(await response.text()))
            } catch (exception) {
                //store.dispatch({ type: response })
                return
            }

            store.dispatch({ type: GET_WARRANTY_GROUPS_ACTIONS.GET_WARRANTY_GROUPS_FAILED })

            store.dispatch({ type: BASE_ACTIONS.ERROR_MESSAGE, message: error.message, code: error.code })
        })
}
