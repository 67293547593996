import { Outlet, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../../state'
import { MessageModal } from '../../component/modal'
import { BASE_ACTIONS } from '../../action/base/base.constants'
import { useTranslation } from 'react-i18next'
import { Language } from '../../enum/language.enum'
import './login.layout.scss'

export const LoginLayout = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { t, i18n } = useTranslation()
    const { errorMessage, errorTitle } = useSelector((state: State) => state.base)

    const onChangeLanguageClick = (lng: string) => {
        i18n.changeLanguage(lng)
    }

    return (
        <div className="layout layout--login">
            <div className="background">
                <div className="layer" />
            </div>

            <img alt="WarrantyPhoto logo" className="logo" src="/image/logo_with_bg.svg" onClick={() => navigate('/')}/>

            <header></header>

            <div className="language-drop-down">
                <button className="button button--secondary">{t(Language.fromLanguageCodeToLabel(i18n.language))}</button>

                <div className="language-drop-down-content">
                    <a onClick={() => onChangeLanguageClick('hu')}>{t('language_hungarian_title')}</a>
                    <a onClick={() => onChangeLanguageClick('en')}>{t('language_english_title')}</a>
                    <a onClick={() => onChangeLanguageClick('ro')}>{t('language_romanian_title')}</a>
                    <a onClick={() => onChangeLanguageClick('hr')}>{t('language_croatian_title')}</a>
                    <a onClick={() => onChangeLanguageClick('cs')}>{t('language_czech_title')}</a>
                    <a onClick={() => onChangeLanguageClick('sl')}>{t('language_slovenian_title')}</a>
                    <a onClick={() => onChangeLanguageClick('sk')}>{t('language_slovak_title')}</a>
                </div>
            </div>

            <Outlet />

            <label className="copyright">{t('login_copyright')}</label>

            {errorMessage ? (
                <MessageModal
                    title={t(errorTitle ?? '')}
                    message={t(errorMessage)}
                    onCloseClick={() => {
                        dispatch({ type: BASE_ACTIONS.CLEAR_ERROR })
                    }}
                />
            ) : null}
        </div>
    )
}
