import { createRef, MouseEvent, useState } from 'react'
import { useSelector } from 'react-redux'
import { LoadingIcon } from '../../component/icon'
import { State } from '../../state'
import { Checkbox } from '../../component'
import { Navigate } from 'react-router-dom'
import { editUser } from '../../action/users/edit-user.action'
import { useTranslation } from 'react-i18next'
import { Language } from '../../enum/language.enum'
import './terms.page.scss'

export const TermsPage = () => {
    const { t, i18n } = useTranslation()
    const authenticatedUser = useSelector((state: State) => state.session.authenticatedUser)
    const { loginStarted } = useSelector((state: State) => state.login)
    const formElement = createRef<HTMLFormElement>()
    const [accepted, setAccepted] = useState(false)

    if (authenticatedUser !== null && authenticatedUser?.termsAccepted) {
        return <Navigate to={'/'} />
    }

    const onAcceptButtonClick = async (event: MouseEvent) => {
        event.preventDefault()

        await editUser()
    }

    return (
        <main className="page--terms">
            <form action="" className="terms-form" ref={formElement}>
                <h2>{t('terms_title')}</h2>

                <iframe className="terms-of-use-frame" src={`/terms/terms_${Language.fromLanguageCode(i18n.language)}.html`}></iframe>


                <div className="agree-container">
                    <Checkbox
                        checked={accepted}
                        id="terms-agree"
                        onChange={() => {
                            setAccepted(!accepted)
                        }}
                    />

                    <label>{t('terms_agree_title')}</label>
                </div>

                <footer>
                    <button className={`button`} disabled={loginStarted !== null || !accepted} onClick={onAcceptButtonClick} type="submit">
                        {loginStarted !== null ? <LoadingIcon /> : t('terms_accept_button')}
                    </button>
                </footer>
            </form>
        </main>
    )
}
