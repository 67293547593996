import { Expose, Type } from 'class-transformer'
import { ValidateNested } from 'class-validator'
import { Country } from '../../entity'

export class CountriesDTO {
    @Expose()
    @ValidateNested()
    @Type(() => Country)
    countries!: Country[]
}
