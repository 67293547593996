import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import './dropdown.scss'

interface DropdownProps {
    selectItemTitle: string
    disabled?: boolean
    value: any
    error: any
    items: DropdownItem[]
    onChange: any
}

interface DropdownItem {
    id: number | string
    value: string
}

export const Dropdown = (props: DropdownProps) => {
    const { t } = useTranslation()
    const [showItems, setShowItems] = useState(false)

    const onDropDownClick = () => {
        setShowItems(!showItems)
    }

    const onSelectItem = (item: DropdownItem) => {
        setShowItems(false)
        props.onChange(item.id)
    }

    const getValue = () => {
        const valueItem = props.items.find((x) => x.id === props.value)
        return valueItem !== undefined ? valueItem.value : props.selectItemTitle
    }

    const hasSelectedValue = () => {
        const valueItem = props.items.find((x) => x.id === props.value)
        return valueItem !== undefined
    }

    const ref = useRef<HTMLDivElement>(null)

    function assertIsNode(e: EventTarget | null): asserts e is Node {
        if (!e || !('nodeType' in e)) {
            throw new Error(`Node expected`)
        }
    }

    useEffect(() => {
        const handleOutSideClick = ({ target }: MouseEvent) => {
            assertIsNode(target)

            if (!ref.current?.contains(target)) {
                setShowItems(!showItems)
            }
        }

        if (showItems) {
            window.addEventListener('mousedown', handleOutSideClick)
        }
        return () => {
            window.removeEventListener('mousedown', handleOutSideClick)
        }
    }, [ref, showItems])

    return (
        <div className={`dropdown-container`} ref={ref}>
            <div className={`dropdown${props.disabled === true ? ` disabled` : ``}`}>
                <div className={`dropdown-container`}>
                    <div className={`dropdown-selected-item${hasSelectedValue() ? '' : ' placeholder'}`} onClick={onDropDownClick} id={'dropdown'}>
                        {getValue()}
                    </div>

                    <img className={`dropdown-arrow ${showItems ? 'rotate' : ''}`} alt="Arrow icon" src="/image/ic_arrow_down.svg" onClick={onDropDownClick} />

                    <div style={{ display: showItems ? 'flex' : 'none' }} className={`dropdown-items`}>
                        {props.items.map((item) => (
                            <div className={`dropdown-items-item ${props.value === item.id ? 'selected' : ''}`} key={item.id + 'dropdown'} onClick={() => onSelectItem(item)}>
                                <span id={'dropdown'}>{item != null ? item.value : ''}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className={`error-field${props.error.length > 0 ? `` : ` hidden`}`}>
                <img alt="" className="error-bubble" src="/image/ic_bubble_indicator_error.svg" />
                <p className="error-message">{props.error}</p>
            </div>
        </div>
    )
}
