import { MouseEvent, MouseEventHandler, ChangeEvent, useRef, useState, createRef, useEffect } from 'react'
import { Modal } from '../../../component/modal'
import { useDispatch, useSelector } from 'react-redux'
import { PasswordField, TextField } from '../../../component/field'
import { State } from '../../../state'
import { useTranslation } from 'react-i18next'
import { CHANGE_PASSWORD_ACTIONS } from '../../../action/users/users.constants'
import { changePassword } from '../../../action/users/change-password.action'
import { validatePassword } from '../../../helper/validate.helper'
import './change-password-modal.scss'

interface ChangePasswordModalProps {
    onModalClose?: CallableFunction
    onCloseClick?: MouseEventHandler
}

export const ChangePasswordModal = (props: ChangePasswordModalProps) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const { form } = useSelector((state: State) => state.changePassword)
    const formElement = createRef<HTMLFormElement>()

    const [oldPasswordError, setOldPasswordError] = useState('')
    const [newPasswordError, setNewPasswordError] = useState('')
    const [newPasswordAgainError, setNewPasswordAgainError] = useState('')

    useEffect(() => {}, [])

    const onOldPassswordChange = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        dispatch({ type: CHANGE_PASSWORD_ACTIONS.CHANGE_PASSWORD_CHANGE_OLD_PASSWORD, oldPassword: event.target.value })
    }

    const onNewPassswordChange = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        dispatch({ type: CHANGE_PASSWORD_ACTIONS.CHANGE_PASSWORD_CHANGE_NEW_PASSWORD, newPassword: event.target.value })
    }

    const onNewPassswordAgainChange = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        dispatch({ type: CHANGE_PASSWORD_ACTIONS.CHANGE_PASSWORD_CHANGE_NEW_PASSWORD_AGAIN, newPasswordAgain: event.target.value })
    }

    const onSaveButtonClick = async (event: MouseEvent) => {
        event.preventDefault()

        if (!validateFileds()) {
            return
        }

        if (!formElement.current?.reportValidity()) {
            return
        }

        await changePassword()

        dispatch({ type: CHANGE_PASSWORD_ACTIONS.CHANGE_PASSWORD_CLEAR })

        if (props.onModalClose) {
            props.onModalClose()
        }
    }

    const validateFileds = () => {
        setOldPasswordError('')
        setNewPasswordError('')
        setNewPasswordAgainError('')

        const oldPasswordValidation = validatePassword(form.oldPassword.value)
        if (oldPasswordValidation != null) {
            setOldPasswordError(t(oldPasswordValidation))
            return false
        }
        const newPasswordValidation = validatePassword(form.newPassword.value)
        if (newPasswordValidation != null) {
            setNewPasswordError(t(newPasswordValidation))
            return false
        }
 
        if (form.newPassword.value != form.newPasswordAgain.value) {
            setNewPasswordAgainError(t("error_change_password_password_mismatch"))
            return false
        }

        return true
    }

    return (
        <Modal title={t('profile_change_password_model_title')} onCloseClick={props.onCloseClick}>
            <form action="" className="change-password-modal" ref={formElement}>
                <PasswordField field={form.oldPassword} onChange={onOldPassswordChange} placeholder={t('profile_change_password_model_old_password_placeholder')} />
                <p className={`error-message`}>{oldPasswordError.length > 0 ? oldPasswordError : ''}</p>

                <PasswordField field={form.newPassword} onChange={onNewPassswordChange} placeholder={t('profile_change_password_model_new_password_placeholder')} />
                <p className={`error-message`}>{newPasswordError.length > 0 ? newPasswordError : ''}</p>

                <PasswordField field={form.newPasswordAgain} onChange={onNewPassswordAgainChange} placeholder={t('profile_change_password_model_new_password_again_placeholder')} />
                <p className={`error-message`}>{newPasswordAgainError.length > 0 ? newPasswordAgainError : ''}</p>

                <div className="add-user-modal__buttons">
                    <button className="button" onClick={onSaveButtonClick}>
                        {t('button_change')}
                    </button>
                </div>
            </form>
        </Modal>
    )
}
