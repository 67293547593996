export const BASE_ACTIONS = {
  ERROR_NO_USER: 'ERROR_NO_USER',
  ERROR_TIMEOUT: 'ERROR_TIMEOUT',
  ERROR_UNAUTHORIZED: 'ERROR_UNAUTHORIZED',
  ERROR_UNEXPECTED_RESPONSE: 'ERROR_UNEXPECTED_RESPONSE',
  ERROR_INVALID_JSON: 'ERROR_INVALID_JSON',
  ERROR_INVALID_RESPONSE: 'ERROR_INVALID_RESPONSE',
  ERROR_MESSAGE: 'ERROR_MESSAGE',
  CLEAR_ERROR: 'CLEAR_ERROR',
}

export const BASE_METHODS = {
  GET: 'GET',
  POST: 'POST',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
  PUT: 'PUT',
}
