import { RESET_PASSWORD_ACTIONS, VALIDATE_TOKEN_ACTION } from '../action/users/users.constants'
import { ResetPasswordState } from '../state'

const initialResetPasswordState: ResetPasswordState = {
    form: {
        password: {
            error: '',
            value: '',
        },
        newPassword: {
            error: '',
            value: '',
        },
    },
    token: null,
    forgotten: null,
    createStarted: null,
    resetPasswordRequested: null,
    validToken: true,
}

export const resetPasswordReducer = (state: ResetPasswordState = initialResetPasswordState, action: any): ResetPasswordState => {
    switch (action.type) {
        case RESET_PASSWORD_ACTIONS.RESET_PASSWORD_CHANGE_PASSWORD:
            return { ...state, form: { ...state.form, password: { ...state.form.password, value: action.password } } }
        case RESET_PASSWORD_ACTIONS.RESET_PASSWORD_CHANGE_NEW_PASSWORD:
            return { ...state, form: { ...state.form, newPassword: { ...state.form.newPassword, value: action.newPassword } } }
        case RESET_PASSWORD_ACTIONS.RESET_PASSWORD_SET_TOKEN_AND_FORGOTTEN:
            return { ...state, token: action.token, forgotten: action.forgotten }
        case RESET_PASSWORD_ACTIONS.RESET_PASSWORD_STARTED:
            return { ...state, createStarted: new Date(), resetPasswordRequested: false }
        case RESET_PASSWORD_ACTIONS.RESET_PASSWORD_OK:
            return { ...state, createStarted: null, resetPasswordRequested: true, form: { ...state.form, password: { ...state.form.password, value: '' }, newPassword: { ...state.form.newPassword, value: '' } } }
        case RESET_PASSWORD_ACTIONS.RESET_PASSWORD_FAILED:
            return { ...state, createStarted: null, resetPasswordRequested: null }
        case VALIDATE_TOKEN_ACTION.VALIDATE_TOKEN_ACTION_OK:
            return { ...state, validToken: action.success }
        case VALIDATE_TOKEN_ACTION.VALIDATE_TOKEN_ACTION_FAILED:
            return { ...state, validToken: true }
        default:
            return state
    }
}
