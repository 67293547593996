import { useSelector } from 'react-redux'
import { State } from '../../state'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import './warranty-photo.page.scss'

interface WarrantyPhotoPageProps {}

export const WarrantyPhotoPage = (props: WarrantyPhotoPageProps) => {
    const { t } = useTranslation()
    const { id } = useParams()
    const { photo } = useSelector((state: State) => state.photo)

    return (
        <main className="page page-warranty-photo">
            <div className="main-div">
                {photo != null ? (
                    <>
                        <div className="navigation-bar-div">
                            <h1>
                                {t('warranty_detail_first_title')} {photo?.warranty?.vinNumber} - {t('warranty_detail_second_title')} {photo?.warranty?.id}
                            </h1>
                            <p>
                                {t('photo_info_modal_title')} {id} - {t('photo_info_version_title')} {photo?.metadata?.version}
                            </p>
                        </div>

                        {/* <PhotoEditor photo={photo!!} editable={false} /> */}

                        <img alt="Photo" className="photo" src={photo.coveredUrl} />

                        <a href={photo.coveredUrl} download>
                            <button className="button">{t('button_download')}</button>
                        </a>
                    </>
                ) : (
                    <p>{t('error_photo_not_found')}</p>
                )}
            </div>
        </main>
    )
}
