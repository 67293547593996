import { MouseEvent as ReactMouseEvent } from 'react'
import { Menu } from '../menu'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { LOGOUT_ACTIONS } from '../../action/users/users.constants'
import { State } from '../../state'
import { useTranslation } from 'react-i18next'
import './sidebar.scss'

let grabbed: boolean = false

const scrollMouseDown = (event: ReactMouseEvent) => {
    event.preventDefault()
    grabbed = true
}

window.addEventListener('mousemove', (event: MouseEvent) => {
    if (grabbed) {
        const scrollbar = document.querySelector(`.scroll-bar`) as HTMLElement
        const menu = document.querySelector(`.menu`) as HTMLElement
        menu.scrollTop += event.movementY * (menu.scrollHeight / scrollbar.scrollHeight)
    }
})

window.addEventListener('mouseup', (event: MouseEvent) => {
    grabbed = false
})

interface SidebarProps {
    onMobileMenuToggle?: (open: boolean) => void
}

export const Sidebar = (props: SidebarProps) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { t } = useTranslation()

    const authenticatedUser = useSelector((state: State) => state.session.authenticatedUser)

    const onMobileMenuToggle = (open: boolean) => {
        if (props.onMobileMenuToggle) props.onMobileMenuToggle(open)
    }

    return (
        <div className="sidebar">
            <img alt="WarrantyPhoto logo" className="logo" src="/image/logo.svg" />

            <Menu onMobileMenuToggle={(open) => onMobileMenuToggle(open)} />

            <div
                className="profile"
                onClick={(e) => {
                    e.preventDefault()
                    navigate(`/profile`)
                }}
            >
                <div className="profile__name-layout">
                    <img className="icon" alt="Settings icon" src="/image/ic_settings.svg" />

                    <label className="name">{authenticatedUser?.firstName + ' ' + authenticatedUser?.lastName}</label>
                </div>

                <label className="email">{authenticatedUser?.email}</label>
            </div>

            <Link to="/login" onClick={(e) => dispatch({ type: LOGOUT_ACTIONS.LOGOUT })} style={{ textDecoration: 'none' }}>
                <div className="button logout-button">
                    <img className="logout-button__icon" alt="Loout icon" src="/image/ic_log_out.svg" />
                    <span className="logout-button__title">{t('logout_button')}</span>
                </div>
            </Link>

            <div className="scroll-bar">
                <div className="scroll" onMouseDown={scrollMouseDown} />
            </div>
        </div>
    )
}
