import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { ErrorDTO } from '../../dto'
import { store } from '../../store'
import { BASE_ACTIONS, BASE_METHODS } from '../base/base.constants'
import { createCall } from '../base'
import { getWarrantyGroups } from './get-warranty-groups.action'
import { WarrantyDTO } from '../../dto/warranties'
import { CREATE_WARRANTY_ACTIONS } from './warranties.constants'

export const createWarranty = async () => {
    store.dispatch({ type: CREATE_WARRANTY_ACTIONS.CREATE_WARRANTY_STARTED })

    const body = JSON.stringify({
        vinNumber: store.getState().warranties.form.segment !== 3 ? store.getState().warranties.form.vinNumber.value : store.getState().warranties.form.vinNumberFirst.value + '-' + store.getState().warranties.form.vinNumberSecound.value,
        segment: store.getState().warranties.form.segment,
    })

    const promise = createCall(`warranties`, true, BASE_METHODS.POST, body)
    promise
        .then(async (response) => {
            let body: WarrantyDTO

            try {
                body = plainToClass(WarrantyDTO, JSON.parse(await response.text()))
            } catch (exception) {
                store.dispatch({ type: BASE_ACTIONS.ERROR_INVALID_JSON })
                return
            }

            if ((await validate(body)).length > 0) {
                store.dispatch({ type: BASE_ACTIONS.ERROR_INVALID_RESPONSE })
                return
            }

            store.dispatch({ type: CREATE_WARRANTY_ACTIONS.CREATE_WARRANTY_OK })

            store.dispatch({ type: BASE_ACTIONS.CLEAR_ERROR })

            getWarrantyGroups()
        })
        .catch(async (response) => {
            let error: ErrorDTO

            try {
                error = plainToClass(ErrorDTO, JSON.parse(await response.text()))
            } catch (exception) {
                //store.dispatch({ type: response })
                return
            }

            store.dispatch({ type: CREATE_WARRANTY_ACTIONS.CREATE_WARRANTY_FAILED })

            store.dispatch({ type: BASE_ACTIONS.ERROR_MESSAGE, message: error.message, code: error.code })
        })
}
