import { Expose } from 'class-transformer'
import { IsDefined, IsNumber, IsOptional, IsString, ValidateIf } from 'class-validator'
import { User } from './user.entity'

export class Dealership {
    @Expose()
    @IsDefined()
    @IsNumber()
    id!: number

    @Expose()
    @IsDefined()
    @IsString()
    language!: string

    @Expose()
    @IsDefined()
    @IsString()
    country!: string

    @Expose()
    @IsDefined()
    @IsString()
    serviceCode!: string

    @Expose()
    @IsDefined()
    @IsString()
    serviceName!: string

    @Expose()
    @IsDefined()
    @IsNumber()
    status!: number

    @Expose()
    @IsDefined()
    @IsString()
    segments!: string

    @Expose()
    @IsDefined()
    @IsNumber()
    @ValidateIf((object, value) => value !== null)
    deactivatedDate!: number | null

    @Expose()
    @IsDefined()
    @IsNumber()
    created!: number

    @IsOptional()
    @IsDefined()
    @IsNumber()
    updated!: number

    @IsOptional()
    @IsDefined()
    @ValidateIf((object, value) => value !== null)
    users?: [User] | null
}
