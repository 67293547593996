import { LoaderFunctionArgs } from 'react-router-dom'
import { GET_WARRANTIES_ACTIONS } from '../../action/warranties/warranties.constants'
import { store } from '../../store'
import { getWarranties } from '../../action'

export const warrantiesPageLoader = async (args: LoaderFunctionArgs) => {
    store.dispatch({ type: GET_WARRANTIES_ACTIONS.GET_WARRANTIES_SEARCH, search: '' })

    await getWarranties(args.params.vin as string)

    return null
}
