import { GET_WARRANTY_GROUPS_ACTIONS } from '../action/warranties/warranties.constants'
import { WarrantyGroupState } from '../state/warranty-group.state'

const initialWarrantyGroupState: WarrantyGroupState = {
    warrantyGroups: [],
    pagination: {
        page: 1,
        pageSize: 0,
        count: 0,
    },
    search: '',
    order: 'updated',
    sort: 'DESC',
    segment: null,
}

export const warrantyGroupReducer = (state: WarrantyGroupState = initialWarrantyGroupState, action: any): WarrantyGroupState => {
    switch (action.type) {
        case GET_WARRANTY_GROUPS_ACTIONS.GET_WARRANTY_GROUPS_OK:
            return { ...state, warrantyGroups: action.warranties, pagination: { ...state.pagination, page: action.page, pageSize: action.pageSize, count: action.count } }
        case GET_WARRANTY_GROUPS_ACTIONS.GET_WARRANTY_GROUPS_CHANGE_PAGE:
            return { ...state, pagination: { ...state.pagination, page: action.page } }
        case GET_WARRANTY_GROUPS_ACTIONS.GET_WARRANTY_GROUPS_CHANGE_ORDER:
            return { ...state, order: action.orderBy, sort: action.sort }
        case GET_WARRANTY_GROUPS_ACTIONS.GET_WARRANTY_GROUPS_CHANGE_SEGMENT:
            return { ...state, segment: action.segment, pagination: { ...state.pagination, page: 1 } }
        case GET_WARRANTY_GROUPS_ACTIONS.GET_WARRANTY_GROUPS_SEARCH:
            return { ...state, pagination: { ...state.pagination, page: 1 }, search: action.search }
        case GET_WARRANTY_GROUPS_ACTIONS.GET_WARRANTY_GROUPS_FAILED:
            return { ...state }
        default:
            return state
    }
}
