import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { DefaultDTO, ErrorDTO } from '../../dto'
import { store } from '../../store'
import { BASE_ACTIONS, BASE_METHODS } from '../base/base.constants'
import { createCall } from '../base'
import { getAllUser } from './get-all-user.action'
import { VALIDATE_TOKEN_ACTION } from './users.constants'

export const validateToken = async (token: string) => {
    store.dispatch({ type: VALIDATE_TOKEN_ACTION.VALIDATE_TOKEN_ACTION_STARTED })

    const promise = createCall(`users/validate-token?token=${token}`, false, BASE_METHODS.GET)
    promise
        .then(async (response) => {
            let body: DefaultDTO

            try {
                body = plainToClass(DefaultDTO, JSON.parse(await response.text()))
            } catch (exception) {
                store.dispatch({ type: BASE_ACTIONS.ERROR_INVALID_JSON })
                return
            }

            if ((await validate(body)).length > 0) {
                store.dispatch({ type: BASE_ACTIONS.ERROR_INVALID_RESPONSE })
                return
            }

            store.dispatch({ type: VALIDATE_TOKEN_ACTION.VALIDATE_TOKEN_ACTION_OK, success: body.success })

            store.dispatch({ type: BASE_ACTIONS.CLEAR_ERROR })

            getAllUser()
        })
        .catch(async (response) => {
            let error: ErrorDTO

            try {
                error = plainToClass(ErrorDTO, JSON.parse(await response.text()))
            } catch (exception) {
                //store.dispatch({ type: response })
                return
            }

            store.dispatch({ type: VALIDATE_TOKEN_ACTION.VALIDATE_TOKEN_ACTION_FAILED })

            store.dispatch({ type: BASE_ACTIONS.ERROR_MESSAGE, message: error.message, code: error.code })
        })
}
