interface RectangleDrawingViewProps {
    width: number
    height: number
    left: number
    top: number
}

export const RectangleDrawingView = (props: RectangleDrawingViewProps) => {
    var circleStyle = {
        padding: 0,
        margin: 0,
        display: 'inline-block',
        backgroundColor: 'white',
        width: props.width,
        height: props.height,
        left: props.left - props.width / 2,
        top: props.top - props.height / 2,
    }

    return <div style={{ position: 'absolute', ...circleStyle }}></div>
}
