import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { ErrorDTO, DefaultDTO } from '../../dto'
import { store } from '../../store'
import { BASE_ACTIONS, BASE_METHODS } from '../base/base.constants'
import { CHANGE_PASSWORD_ACTIONS } from './users.constants'
import { createCall } from '../base'

export const changePassword = async () => {
    store.dispatch({ type: CHANGE_PASSWORD_ACTIONS.CHANGE_PASSWORD_STARTED })

    const body = JSON.stringify({
        password: store.getState().changePassword.form.oldPassword.value,
        newPassword: store.getState().changePassword.form.newPassword.value,
    })

    const promise = createCall(`users/change-password`, false, BASE_METHODS.PATCH, body)
    promise
        .then(async (response) => {
            let body: DefaultDTO

            try {
                body = plainToClass(DefaultDTO, JSON.parse(await response.text()))
            } catch (exception) {
                store.dispatch({ type: BASE_ACTIONS.ERROR_INVALID_JSON })
                return
            }

            if ((await validate(body)).length > 0) {
                store.dispatch({ type: BASE_ACTIONS.ERROR_INVALID_RESPONSE })
                return
            }

            store.dispatch({ type: CHANGE_PASSWORD_ACTIONS.CHANGE_PASSWORD_OK })

            store.dispatch({ type: BASE_ACTIONS.CLEAR_ERROR })
        })
        .catch(async (response) => {
            let error: ErrorDTO

            try {
                error = plainToClass(ErrorDTO, JSON.parse(await response.text()))
            } catch (exception) {
                //store.dispatch({ type: response })
                return
            }

            store.dispatch({ type: CHANGE_PASSWORD_ACTIONS.CHANGE_PASSWORD_FAILED })

            store.dispatch({ type: BASE_ACTIONS.ERROR_MESSAGE, message: error.message, code: error.code })
        })
}
