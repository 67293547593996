import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { ErrorDTO, UserDTO } from '../../dto'
import { store } from '../../store'
import { BASE_ACTIONS, BASE_METHODS } from '../base/base.constants'
import { LOIGN_ACTIONS } from './users.constants'
import { createCall } from '../base'

export const editUser = async () => {
    store.dispatch({ type: LOIGN_ACTIONS.LOGIN_STARTED })

    const body = JSON.stringify({
        email: store.getState().session.authenticatedUser?.email,
        firstName: store.getState().session.authenticatedUser?.firstName,
        lastName: store.getState().session.authenticatedUser?.lastName,
        dealershipId: store.getState().session.authenticatedUser?.dealership?.id,
        language: store.getState().session.authenticatedUser?.language,
        role: store.getState().session.authenticatedUser?.role,
        termsAccepted: true,
    })

    const promise = createCall(`users/` + store.getState().session.authenticatedUser?.id, true, BASE_METHODS.PATCH, body)
    promise
        .then(async (response) => {
            let body: UserDTO

            try {
                body = plainToClass(UserDTO, JSON.parse(await response.text()))
            } catch (exception) {
                store.dispatch({ type: BASE_ACTIONS.ERROR_INVALID_JSON })
                return
            }

            if ((await validate(body)).length > 0) {
                store.dispatch({ type: BASE_ACTIONS.ERROR_INVALID_RESPONSE })
                return
            }

            store.dispatch({ type: 'GET_ME_OK', user: body.user })

            store.dispatch({ type: BASE_ACTIONS.CLEAR_ERROR })
        })
        .catch(async (response) => {
            let error: ErrorDTO

            try {
                error = plainToClass(ErrorDTO, JSON.parse(await response.text()))
            } catch (exception) {
                //store.dispatch({ type: response })
                return
            }

            store.dispatch({ type: LOIGN_ACTIONS.LOGIN_FAILED })

            store.dispatch({ type: BASE_ACTIONS.ERROR_MESSAGE, message: error.message, code: error.code })
        })
}
