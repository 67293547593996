import { store } from '../../store'
import { BASE_ACTIONS } from './base.constants'

export const createCall = async (url: string, authRequired: boolean, method: string, body?: string, headers?: HeadersInit): Promise<Response> => {
    let response: Response

    const token = store.getState().session.token

    if (token === null && authRequired) {
        return new Promise<Response>((res, reject) => {
            reject(BASE_ACTIONS.ERROR_NO_USER)
            return
        })
    }

    return new Promise<Response>(async (res, reject) => {
        try {
            response = await fetch(`${process.env.REACT_APP_API_ORIGIN}/1.0.0/` + url, {
                body: body,
                headers: {
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'App-Platform': 'cms',
                    ...headers,
                },
                method: method,
            })
        } catch (exception) {
            reject(BASE_ACTIONS.ERROR_TIMEOUT)
            return
        }

        if (response.status === 401) {
            reject(response)
            return
        }

        //Ha hiba jön a szerverről, ezt minden actin egyedileg kezeli a saját hibakódja szerint
        if (response.status === 400) {
            reject(response)
            return
        }

        if (![200, 201].includes(response.status)) {
            reject(BASE_ACTIONS.ERROR_UNEXPECTED_RESPONSE)
            return
        }

        res(response)
    })
}
