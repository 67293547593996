import { MouseEvent, useRef, useState, useEffect } from 'react'
import { Cover, Photo } from '../../../../entity'
import { RectangleDrawingView } from './covers/rectangle-drawing-view'
import { EllipseDrawingView } from './covers/ellipse-drawing-view'
import { FreehandDrawingView } from './covers/freehand-drawing-view'
import { createFormattedDateTimeString } from '../../../../helper'
import { DrawFreehand } from './covers/draw-freehand'
import { useDispatch, useSelector } from 'react-redux'
import { PHOTO_EDITOR_ACTIONS } from '../../../../action/photos/photos.constants'
import { State } from '../../../../state'
import { EditableRectangle } from './covers/editable-rectangle'
import { EditableEllipse } from './covers/editable-ellipse'
import { useTranslation } from 'react-i18next'
import './photo-editor.scss'

interface PhotoEditorProps {
    photo: Photo
    editable: boolean
    onDrawEnd?: CallableFunction
}

export const PhotoEditor = (props: PhotoEditorProps) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const photoRef = useRef<HTMLImageElement>(null)
    const [width, setWidth] = useState(0)
    const [height, setHeight] = useState(0)

    const { covers, photo, tool } = useSelector((state: State) => state.editor)

    useEffect(() => {
        if (props.photo) {
            dispatch({ type: PHOTO_EDITOR_ACTIONS.CLEAR, photo: props.photo })
            dispatch({ type: PHOTO_EDITOR_ACTIONS.ACCEPT_COVER, acceptCover: props.photo.metadata?.covers != null && props.photo.metadata?.covers.length > 0 ? false : null })
            dispatch({ type: PHOTO_EDITOR_ACTIONS.INITIALIZE, photo: props.photo })
        }
    }, [])

    const onPhotoLoad = () => {
        setWidth(photoRef.current!!.offsetWidth)
        setHeight(photoRef.current!!.offsetHeight)
    }

    const onFreehandButtonClick = async (event: MouseEvent) => {
        event.preventDefault()

        dispatch({ type: PHOTO_EDITOR_ACTIONS.SELECTED_TOOL, tool: tool === 1 ? null : 1 })

        dispatch({ type: PHOTO_EDITOR_ACTIONS.IS_TOOLS_ENABLED, enabled: tool != null ? false : true })
    }

    const onRectangleButtonClick = async (event: MouseEvent) => {
        event.preventDefault()
        dispatch({ type: PHOTO_EDITOR_ACTIONS.SELECTED_TOOL, tool: tool === 2 ? null : 2 })

        dispatch({ type: PHOTO_EDITOR_ACTIONS.IS_TOOLS_ENABLED, enabled: tool != null ? false : true })
    }

    const onEllipseButtonClick = async (event: MouseEvent) => {
        event.preventDefault()
        dispatch({ type: PHOTO_EDITOR_ACTIONS.SELECTED_TOOL, tool: tool === 3 ? null : 3 })

        dispatch({ type: PHOTO_EDITOR_ACTIONS.IS_TOOLS_ENABLED, enabled: tool != null ? false : true })
    }

    return (
        <div className="photo-editor">
            <div className="photo-editor-editor">
                <img alt="Photo" className="photo" ref={photoRef} onLoad={onPhotoLoad} src={props.photo.url} />

                {props.photo.metadata?.created!! - props.photo.metadata?.mobileCreated!! > 600 ? <label className="offline">{t('warranty_card_offline')}</label> : null}

                {props.editable ? (
                    <div className="editor">
                        {(() => {
                            let tools = []

                            if (tool != null) {
                                if (tool === 1) {
                                    tools.push(
                                        <DrawFreehand
                                            key={'draw_freehand'}
                                            width={width}
                                            height={height}
                                            widthRaito={width / photo!!.metadata!!.width}
                                            onDrawEnd={(cover: Cover) => {
                                                // if(props.onDrawEnd){
                                                //     props.onDrawEnd(cover)
                                                // }
                                                dispatch({ type: PHOTO_EDITOR_ACTIONS.ADD_COVER, cover: cover })
                                            }}
                                        />
                                    )
                                }

                                if (tool === 2) {
                                    tools.push(
                                        <EditableRectangle
                                            key={'editable_rectangle'}
                                            width={width}
                                            height={height}
                                            widthRaito={width / photo!!.metadata!!.width}
                                            onDrawEnd={(cover: Cover) => {
                                                if (props.onDrawEnd) {
                                                    props.onDrawEnd(cover)
                                                }
                                                // dispatch({ type: PHOTO_EDITOR_ACTIONS.ADD_COVER, cover: cover })
                                            }}
                                        />
                                    )
                                }

                                if (tool === 3) {
                                    tools.push(
                                        <EditableEllipse
                                            key={'editable_ellipse'}
                                            width={width}
                                            height={height}
                                            widthRaito={width / photo!!.metadata!!.width}
                                            onDrawEnd={(cover: Cover) => {
                                                if (props.onDrawEnd) {
                                                    props.onDrawEnd(cover)
                                                }
                                                // dispatch({ type: PHOTO_EDITOR_ACTIONS.ADD_COVER, cover: cover })
                                            }}
                                        />
                                    )
                                }
                            }

                            return tools
                        })()}
                    </div>
                ) : null}

                <div className="covers" style={{ height: height }}>
                    {(() => {
                        let objects = []

                        if (photo != null && photo.metadata != null && covers != null) {
                            for (let i = 0; i < covers.length; i++) {
                                const cover = covers[i]

                                const ratioWidth = width / photo.metadata.width
                                const ratioHeight = height / photo.metadata.height

                                if (cover.type === 1) {
                                    objects.push(<RectangleDrawingView key={'rd' + cover.id} width={(cover.width ?? 0) * ratioWidth} height={(cover.height ?? 0) * ratioWidth} left={(cover.position?.x ?? 0) * ratioWidth} top={(cover.position?.y ?? 0) * ratioWidth} />)
                                }

                                if (cover.type === 2) {
                                    objects.push(<EllipseDrawingView key={'ed' + cover.id} width={(cover.width ?? 0) * ratioWidth} height={(cover.height ?? 0) * ratioWidth} left={(cover.position?.x ?? 0) * ratioWidth} top={(cover.position?.y ?? 0) * ratioWidth} />)
                                }

                                if (cover.type === 3) {
                                    objects.push(<FreehandDrawingView key={'fh' + cover.id} ratioWidth={ratioWidth} ratioHeight={ratioHeight} width={width} height={height} points={cover.points ?? []} left={(cover.position?.x ?? 0) * ratioWidth} top={(cover.position?.y ?? 0) * ratioHeight} />)
                                }
                            }
                        }

                        return objects
                    })()}
                </div>

                <label className="time">{createFormattedDateTimeString(props.photo.created)}</label>
            </div>

            {props.editable ? (
                <div className="photo-editor-tools">
                    <button className={'button-icon ' + `${tool === 1 || tool == null ? '' : 'hidden'}`} onClick={onFreehandButtonClick}>
                        <img alt="Freehand icon" src="/image/ic_freehand.svg" />
                    </button>

                    <button className={'button-icon ' + `${tool === 2 || tool == null ? '' : 'hidden'}`} onClick={onRectangleButtonClick}>
                        <img alt="Rectangle icon" src="/image/ic_rectangle.svg" />
                    </button>

                    <button className={'button-icon ' + `${tool === 3 || tool == null ? '' : 'hidden'}`} onClick={onEllipseButtonClick}>
                        <img alt="Ellipse icon" src="/image/ic_ellipse.svg" />
                    </button>
                </div>
            ) : null}
        </div>
    )
}
