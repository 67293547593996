import { CREATE_USER_ACTIONS, GET_ALL_USER_ACTIONS } from '../action/users/users.constants'
import { UsersState } from '../state'

const initialUsersState: UsersState = {
    users: [],
    pagination: {
        page: 1,
        pageSize: 0,
        count: 0,
    },
    search: '',
    order: 'user.lastName',
    sort: 'DESC',
    role: null,

    createUserForm: {
        userId: null,

        firstName: {
            error: '',
            value: '',
        },
        lastName: {
            error: '',
            value: '',
        },
        email: {
            error: '',
            value: '',
            disabled: false,
        },
        role: 0,
        dealership: null,
        language: null,
    },

    createUserStarted: undefined,
}

export const usersReducer = (state: UsersState = initialUsersState, action: any): UsersState => {
    switch (action.type) {
        case GET_ALL_USER_ACTIONS.GET_ALL_USER_OK:
            return { ...state, users: action.users, pagination: { ...state.pagination, page: action.page, pageSize: action.pageSize, count: action.count } }
        case GET_ALL_USER_ACTIONS.GET_ALL_USER_CHANGE_PAGE:
            return { ...state, pagination: { ...state.pagination, page: action.page } }
        case GET_ALL_USER_ACTIONS.GET_ALL_USER_CHANGE_ROLE:
            return { ...state, role: action.role }
        case GET_ALL_USER_ACTIONS.GET_ALL_USER_CHANGE_ORDER:
            return { ...state, order: action.orderBy, sort: action.sort }
        case GET_ALL_USER_ACTIONS.GET_ALL_USER_SEARCH:
            return { ...state, pagination: { ...state.pagination, page: 1 }, search: action.search }
        case GET_ALL_USER_ACTIONS.GET_ALL_USER_FAILED:
            return { ...state }

        case CREATE_USER_ACTIONS.CREATE_USER_CHANGE_USER_ID:
            return { ...state, createUserForm: { ...state.createUserForm, userId: action.userId, email: { ...state.createUserForm.email, disabled: true } } }
        case CREATE_USER_ACTIONS.CREATE_USER_CHANGE_FIRST_NAME:
            return { ...state, createUserForm: { ...state.createUserForm, firstName: { ...state.createUserForm.firstName, value: action.firstName } } }
        case CREATE_USER_ACTIONS.CREATE_USER_CHANGE_LAST_NAME:
            return { ...state, createUserForm: { ...state.createUserForm, lastName: { ...state.createUserForm.lastName, value: action.lastName } } }
        case CREATE_USER_ACTIONS.CREATE_USER_CHANGE_EMAIL:
            return { ...state, createUserForm: { ...state.createUserForm, email: { ...state.createUserForm.email, value: action.email } } }
        case CREATE_USER_ACTIONS.CREATE_USER_CHANGE_ROLE:
            return { ...state, createUserForm: { ...state.createUserForm, role: action.roleId } }
        case CREATE_USER_ACTIONS.CREATE_USER_CHANGE_LANGUAGE:
            return { ...state, createUserForm: { ...state.createUserForm, language: action.language } }
        case CREATE_USER_ACTIONS.CREATE_USER_CHANGE_DEALERSHIP:
            return { ...state, createUserForm: { ...state.createUserForm, dealership: action.dealership } }
        case CREATE_USER_ACTIONS.CREATE_USER_CLEAR:
            return { ...state, createUserForm: { ...state.createUserForm, firstName: { ...state.createUserForm.firstName, value: '' }, lastName: { ...state.createUserForm.lastName, value: '' }, email: { ...state.createUserForm.email, value: '', disabled: false }, role: 0, language: null, userId: null, dealership: null } }
        case CREATE_USER_ACTIONS.CREATE_USER_STARTED:
            return { ...state, createUserStarted: new Date() }
        case CREATE_USER_ACTIONS.CREATE_USER_OK:
            return { ...state, createUserStarted: null }
        case CREATE_USER_ACTIONS.CREATE_USER_FAILED:
            return { ...state, createUserStarted: undefined }
        default:
            return state
    }
}
