export const validateEmail = (email: string) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    if (!email || email.length === 0) {
        return 'error_login_email_empty'
    }

    if (!regex.test(email)) {
        return 'error_login_email_wrong'
    }

    return null
}

//    ^(?=.*[A-Z].*[A-Z])(?=.*[!@#$&*])(?=.*[0-9].*[0-9])(?=.*[a-z].*[a-z].*[a-z]).{8}$
//    ^                         Start anchor
//    (?=.*[A-Z].*[A-Z])        Ensure string has two uppercase letters.
//    (?=.*[!@#$&*])            Ensure string has one special case letter.
//    (?=.*[0-9].*[0-9])        Ensure string has two digits.
//    (?=.*[a-z].*[a-z].*[a-z]) Ensure string has three lowercase letters.
//    .{8}                      Ensure string is of length 8.
//    $                         End anchor.
export const validatePassword = (password: string, onlyLength: boolean = false) => {
    if (!password || password.length === 0) {
        return 'error_login_password_empty'
    }

    //const regex = /^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#\$%^&*()\\-_=+{}|?>.<,:;~`’]{8,}\$/
    //  if (!regex.test(password)) {
    //      return 'error_change_password_password_wrong'
    //  }

    if (!onlyLength) {
        var lowerCase = /[a-z]/g
        var upperCase = /[A-Z]/g
        var numbers = /[0-9]/g

        if (!password.match(lowerCase)) {
            //console.log('Password should contains lowercase letters!')
            return 'error_change_password_password_wrong'
        } else if (!password.match(upperCase)) {
            //console.log('Password should contain uppercase letters!')
            return 'error_change_password_password_wrong'
        } else if (!password.match(numbers)) {
            //console.log('Password should contains numbers also!')
            return 'error_change_password_password_wrong'
        } else if (password.length < 8) {
            //console.log('Password length should be more than 8.')
            return 'error_change_password_password_wrong'
        } else {
            return null
        }
    }

    return null
}
