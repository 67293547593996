import { Expose } from 'class-transformer'
import { IsDefined, IsNumber, IsOptional, IsString, ValidateIf } from 'class-validator'
import { Dealership } from './dealership.entity'
import { Photo } from './photo.entity'

export class Warranty {
    @Expose()
    @IsDefined()
    @IsNumber()
    id!: number

    @Expose()
    @IsDefined()
    @IsNumber()
    segment!: number

    @Expose()
    @IsDefined()
    @IsString()
    vinNumber!: string

    @Expose()
    @IsDefined()
    @IsNumber()
    status!: number

    @Expose()
    @IsOptional()
    @IsString()
    country!: string

    @Expose()
    @IsOptional()
    @IsString()
    serviceCode!: string

    @Expose()
    @IsOptional()
    @IsString()
    serviceName!: string

    @Expose()
    @IsOptional()
    @IsNumber()
    photoCount!: number

    @Expose()
    @IsOptional()
    @ValidateIf((object, value) => value !== null)
    dealership?: Dealership | null

    @Expose()
    @IsOptional()
    @ValidateIf((object, value) => value !== null)
    photos?: [Photo] | null

    @Expose()
    @IsDefined()
    @IsNumber()
    created!: number

    @IsOptional()
    @IsDefined()
    @IsNumber()
    updated!: number
}
