import { LoaderFunctionArgs } from 'react-router-dom'
import { store } from '../../store'
import { getCountries, getDealerships } from '../../action'
import { GET_DEALERSHIPS_ACTIONS } from '../../action/dealerships/dealerships.constants'

export const dealershipsPageLoader = async (args: LoaderFunctionArgs) => {
    store.dispatch({ type: GET_DEALERSHIPS_ACTIONS.GET_DEALERSHIPS_SEARCH, search: '' })

    await getDealerships()

    await getCountries('')

    return null
}
