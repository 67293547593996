import { useDispatch, useSelector } from 'react-redux'
import { Table } from '../../../component'
import { State } from '../../../state'
import { getWarrantyGroups } from '../../../action'
import { GET_WARRANTY_GROUPS_ACTIONS } from '../../../action/warranties/warranties.constants'
import { createFormattedDateString } from '../../../helper'
import { WarrantyGroupsTableSegmentFilter } from './warranty-groups-table-segment-filter'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Sort } from '../../../enum/sort.enum'
import './warranty-groups-table.scss'

export const WarrantyGroupsTable = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { t } = useTranslation()

    const { warrantyGroups, order, sort, segment } = useSelector((state: State) => state.warrantyGroup)
    const { page, pageSize, count } = useSelector((state: State) => state.warrantyGroup.pagination)

    const onPageChange = async (page: number) => {
        dispatch({ type: GET_WARRANTY_GROUPS_ACTIONS.GET_WARRANTY_GROUPS_CHANGE_PAGE, page: page })
        await getWarrantyGroups()
    }

    const onOrderChange = async (column: string) => {
        let direction = Sort.ASC

        if (order === column) {
            if (sort === Sort.ASC) {
                direction = Sort.DESC
            } else if (sort === Sort.DESC) {
                column = ``
                direction = Sort.EMPTY
            }
        }

        dispatch({ type: GET_WARRANTY_GROUPS_ACTIONS.GET_WARRANTY_GROUPS_CHANGE_ORDER, orderBy: column, sort: direction })
        await getWarrantyGroups()
    }

    const columns = [
        {
            canOrder: true,
            caption: t('warranty_table_vin_title'),
            render: (row: any) => row.vinNumber,
            name: `vin_number`,
            width: 262,
        },
        {
            canOrder: true,
            caption: t('warranty_table_isues_title'),
            render: (row: any) => row.count,
            name: `count`,
            width: 252,
        },
        {
            canOrder: true,
            caption: t('warranty_table_modification_title'),
            render: (data: any) => createFormattedDateString(data.updated),
            name: `updated`,
            width: 252,
        },
        {
            canOrder: false,
            render: (data: any) => (data.segment === 1 ? t('segment_automobile_title') : data.segment === 2 ? t('segment_motorcycle_title') : t('segment_marine_title')),
            headerRender: () => <WarrantyGroupsTableSegmentFilter segment={segment} />,
            name: `segment`,
            width: 282,
        },
    ]

    return <Table className="warranty-groups-table" columns={columns} dataset={warrantyGroups !== null && warrantyGroups !== undefined ? warrantyGroups : []} pagination={{ page: page, pageSize: pageSize, count: count, onPageChange: onPageChange }} order={{ orderBy: order, sort: sort }} onOrderChange={onOrderChange} onRowClick={(row) => navigate(`/warranties/${row.vinNumber}`)} />
}
