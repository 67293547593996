import { LOGOUT_ACTIONS, LOIGN_ACTIONS } from '../action/users/users.constants'
import { cookie, message } from '../helper'
import { SessionState } from '../state'

const initialSessionState: SessionState = {
    authenticatedUser: undefined,
    token: cookie('token'),
}

export const sessionReducer = (state: SessionState = initialSessionState, action: any): SessionState => {
    switch (action.type) {
        case 'GET_ME_OK':
            return { ...state, authenticatedUser: action.user }

        case 'GET_ME_ERROR':
            return { ...state, authenticatedUser: null }

        case 'GET_ME_INVALIDJSON':
        case 'GET_ME_INVALIDRESPONSE':
        case 'GET_ME_NOUSER':
        case 'GET_ME_TIMEOUT':
        case 'GET_ME_UNAUTHORIZED':
        case 'GET_ME_UNEXPECTEDRESPONSE':
            return { ...state, authenticatedUser: null }

        case LOIGN_ACTIONS.LOGIN_OK:
            cookie('token', action.token)
            return { ...state, authenticatedUser: action.user, token: action.token }

        case LOGOUT_ACTIONS.LOGOUT:
            cookie('token', '')
            return { ...state, authenticatedUser: null, token: null }

        case `MODIFY_USER_OK`:
            message(`Az adatok sikeresen módosításra kerültek!`)
            return state

        default:
            return state
    }
}
