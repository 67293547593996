import { GET_WARRANTY_ACTIONS } from '../action/warranties/warranties.constants'
import { WarrantyState } from '../state'

const initialWarrantyState: WarrantyState = {
    warranty: null,
    loadingStarted: null,
}

export const warrantyReducer = (state: WarrantyState = initialWarrantyState, action: any): WarrantyState => {
    switch (action.type) {
        case GET_WARRANTY_ACTIONS.GET_WARRANTY_OK:
            return { ...state, warranty: action.warranty, loadingStarted: null }
        case GET_WARRANTY_ACTIONS.GET_WARRANTY_STARTED:
            return { ...state, loadingStarted: new Date() }
        case GET_WARRANTY_ACTIONS.GET_WARRANTY_FAILED:
            return { ...state, warranty: null, loadingStarted: null }
        default:
            return state
    }
}
