export const GET_DEALERSHIPS_ACTIONS = {
    GET_DEALERSHIPS_CHANGE_PAGE: 'GET_DEALERSHIPS_CHANGE_PAGE',
    GET_DEALERSHIPS_CHANGE_ORDER: 'GET_DEALERSHIPS_CHANGE_ORDER',
    GET_DEALERSHIPS_CHANGE_SEGMENT: 'GET_DEALERSHIPS_CHANGE_SEGMENT',
    GET_DEALERSHIPS_SEARCH: 'GET_DEALERSHIPS_SEARCH',
    GET_DEALERSHIPS_STARTED: 'GET_DEALERSHIPS_STARTED',
    GET_DEALERSHIPS_OK: 'GET_DEALERSHIPS_OK',
    GET_DEALERSHIPS_FAILED: 'GET_DEALERSHIPS_FAILED',
}

export const CREATE_DEALERSHIP_ACTIONS = {
    CREATE_DEALERSHIP_CHANGE_DEALERSHIP_ID: 'CREATE_DEALERSHIP_CHANGE_DEALERSHIP_ID',
    CREATE_DEALERSHIP_CHANGE_NAME: 'CREATE_DEALERSHIP_CHANGE_NAME',
    CREATE_DEALERSHIP_CHANGE_CODE: 'CREATE_DEALERSHIP_CHANGE_CODE',
    CREATE_DEALERSHIP_CHANGE_COUNTRY: 'CREATE_DEALERSHIP_CHANGE_COUNTRY',
    CREATE_DEALERSHIP_CHANGE_LANGUAGE: 'CREATE_DEALERSHIP_CHANGE_LANGUAGE',
    CREATE_DEALERSHIP_CHANGE_STATUS: 'CREATE_DEALERSHIP_CHANGE_STATUS',
    CREATE_DEALERSHIP_CHANGE_SEGMENTS: 'CREATE_DEALERSHIP_CHANGE_SEGMENTS',
    CREATE_DEALERSHIP_CHANGE_SEGMENT: 'CREATE_DEALERSHIP_CHANGE_SEGMENT',
    CREATE_DEALERSHIP_CLEAR: 'CREATE_DEALERSHIP_CLEAR',
    CREATE_DEALERSHIP_STARTED: 'CREATE_DEALERSHIP_STARTED',
    CREATE_DEALERSHIP_OK: 'CREATE_DEALERSHIP_OK',
    CREATE_DEALERSHIP_FAILED: 'CREATE_DEALERSHIP_FAILED',
}

export const EXPORT_DEALERSHIPS_ACTION = {
    EXPORT_DEALERSHIPS_ACTION_STARTED: 'EXPORT_DEALERSHIPS_ACTION_STARTED',
    EXPORT_DEALERSHIPS_ACTION_OK: 'EXPORT_DEALERSHIPS_ACTION_OK',
    EXPORT_DEALERSHIPS_ACTION_FAILED: 'EXPORT_DEALERSHIPS_ACTION_FAILED',
}


export const GET_COUNTRIES_ACTIONS = {
    GET_COUNTRIES_ACTIONS_STARTED: 'GET_COUNTRIES_ACTIONS_STARTED',
    GET_COUNTRIES_ACTIONS_OK: 'GET_COUNTRIES_ACTIONS_OK',
    GET_COUNTRIES_ACTIONS_FAILED: 'GET_COUNTRIES_ACTIONS_FAILED',
}