import { useDispatch, useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { BASE_ACTIONS } from '../../action/base/base.constants'
import { Sidebar } from '../../component'
import { MessageModal } from '../../component/modal'
import { State } from '../../state'
import { useTranslation } from 'react-i18next'
import './default.layout.scss'

interface DefaultLayoutProps {}

export const DefaultLayout = (props: DefaultLayoutProps) => {
    const dispatch = useDispatch()

    const { t } = useTranslation()

    const { errorMessage, errorTitle } = useSelector((state: State) => state.base)

    return (
        <div className={`layout layout--default`}>
            <Sidebar />
            <Outlet />

            {errorMessage ? (
                <MessageModal
                    title={t(errorTitle ?? '')}
                    message={t(errorMessage)}
                    onCloseClick={() => {
                        dispatch({ type: BASE_ACTIONS.CLEAR_ERROR })
                    }}
                />
            ) : null}
        </div>
    )
}
