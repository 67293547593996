import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { ErrorDTO } from '../../dto'
import { store } from '../../store'
import { BASE_ACTIONS, BASE_METHODS } from '../base/base.constants'
import { createCall } from '../base'
import { PHOTO_EDITOR_ACTIONS, UPLOAD_PHOTOS_DATA_ACTIONS } from './photos.constants'
import { WarrantyDTO } from '../../dto/warranties'
import { getWarranty } from '../warranties'

export const uploadPhotosData = async (warrantyId: number) => {
    store.dispatch({ type: UPLOAD_PHOTOS_DATA_ACTIONS.UPLOAD_PHOTOS_DATA_ACTIONS_STARTED })

    var coverArray: any[] = []

    store.getState().editor.covers.map((cover) => {
        var pointArray: any[] = []

        if (cover.points != null && cover.points !== undefined) {
            cover.points!!.map((point) => {
                pointArray.push({
                    x: point.x,
                    y: point.y,
                    flags: point.flags,
                })
            })
        }

        coverArray.push({
            type: cover.type,
            width: cover.width,
            height: cover.height,
            position: cover.position,
            radius: cover.radius,
            rotation: cover.rotation,
            points: pointArray,
        })
    })

    const photoData = {
        id: store.getState().editor.photo?.id,
        metadata: {
            width: store.getState().editor.photo?.metadata?.width,
            height: store.getState().editor.photo?.metadata?.height,
            lat: store.getState().editor.photo?.metadata?.lat,
            lon: store.getState().editor.photo?.metadata?.lon,
            mobileCreated: store.getState().editor.photo?.metadata?.mobileCreated,
            edited: true,
            covers: coverArray,
        },
    }

    const body = JSON.stringify({
        warrantyId: warrantyId,
        photos: [photoData],
    })

    const promise = createCall(`photos/data`, true, BASE_METHODS.POST, body)
    promise
        .then(async (response) => {
            let body: WarrantyDTO

            try {
                body = plainToClass(WarrantyDTO, JSON.parse(await response.text()))
            } catch (exception) {
                store.dispatch({ type: BASE_ACTIONS.ERROR_INVALID_JSON })
                return
            }

            if ((await validate(body)).length > 0) {
                store.dispatch({ type: BASE_ACTIONS.ERROR_INVALID_RESPONSE })
                return
            }

            store.dispatch({ type: BASE_ACTIONS.CLEAR_ERROR })

            store.dispatch({ type: UPLOAD_PHOTOS_DATA_ACTIONS.UPLOAD_PHOTOS_DATA_ACTIONS_OK })

            getWarranty(warrantyId)
        })
        .catch(async (response) => {
            let error: ErrorDTO

            try {
                error = plainToClass(ErrorDTO, JSON.parse(await response.text()))
            } catch (exception) {
                //store.dispatch({ type: response })
                return
            }

            store.dispatch({ type: PHOTO_EDITOR_ACTIONS.CLEAR })

            store.dispatch({ type: UPLOAD_PHOTOS_DATA_ACTIONS.UPLOAD_PHOTOS_DATA_ACTIONS_FAILED })

            store.dispatch({ type: BASE_ACTIONS.ERROR_MESSAGE, message: error.message, code: error.code })
        })
}
