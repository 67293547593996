import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { ErrorDTO, WarrantiesDTO } from '../../dto'
import { store } from '../../store'
import { BASE_ACTIONS, BASE_METHODS } from '../base/base.constants'
import { createCall } from '../base'
import { GET_WARRANTIES_ACTIONS } from './warranties.constants'

export const getWarranties = async (vinNumber: string) => {
    store.dispatch({ type: GET_WARRANTIES_ACTIONS.GET_WARRANTIES_STARTED })

    const page = store.getState().warranties.pagination.page
    const search = store.getState().warranties.search
    const orderBy = store.getState().warranties.order
    const sort = store.getState().warranties.sort

    const promise = createCall(`warranties/vin/${vinNumber}?page=${page}&pageSize=${15}${search !== '' ? `&search=${search}` : ``}&orderBy=${orderBy}&sort=${sort}`, true, BASE_METHODS.GET)
    promise
        .then(async (response) => {
            let body: WarrantiesDTO

            try {
                body = plainToClass(WarrantiesDTO, JSON.parse(await response.text()))
            } catch (exception) {
                store.dispatch({ type: BASE_ACTIONS.ERROR_INVALID_JSON })
                return
            }

            if ((await validate(body)).length > 0) {
                store.dispatch({ type: BASE_ACTIONS.ERROR_INVALID_RESPONSE })
                return
            }

            store.dispatch({ type: GET_WARRANTIES_ACTIONS.GET_WARRANTIES_OK, warranties: body.data, page: body.page, pageSize: body.pageSize, count: body.count })

            store.dispatch({ type: BASE_ACTIONS.CLEAR_ERROR })
        })
        .catch(async (response) => {
            let error: ErrorDTO

            try {
                error = plainToClass(ErrorDTO, JSON.parse(await response.text()))
            } catch (exception) {
                //store.dispatch({ type: response })
                return
            }

            store.dispatch({ type: GET_WARRANTIES_ACTIONS.GET_WARRANTIES_FAILED })

            if (error.code === 300001) {
                store.dispatch({ type: GET_WARRANTIES_ACTIONS.GET_WARRANTIES_WARRANTY_NOT_FOUND, value: true })
            } else {
                store.dispatch({ type: BASE_ACTIONS.ERROR_MESSAGE, message: error.message, code: error.code })
            }
        })
}
