export enum Segment {
    AUTOMOBILE = 1,
    MOTORCYCLE = 2,
    MARINE = 3,
}

export namespace Segment {
    export function fromString(segmnets: string): number[] {
        const segmentArray = []
        if (segmnets.includes('1')) {
            segmentArray.push(Segment.AUTOMOBILE)
        }
        if (segmnets.includes('2')) {
            segmentArray.push(Segment.MOTORCYCLE)
        }
        if (segmnets.includes('3')) {
            segmentArray.push(Segment.MARINE)
        }
        return segmentArray
    }
}
