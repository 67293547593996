import ReactDOM from 'react-dom/client'
import 'reflect-metadata'
//import { initializeApp } from 'firebase/app'
//import { getMessaging, onMessage } from 'firebase/messaging'
import { App } from './App'
import './asset/scss/index.scss'
import './i18n'

function setInnerHeight() {
    document.body.setAttribute(`style`, `--inner-height: ${window.innerHeight}px`)
}
setInnerHeight()
window.onresize = setInnerHeight

// const firebaseConfig = {
//     apiKey: "AIzaSyC7z7xCVR7Bq7UL1b3r3uonkXoc3GYlKEQ",
//     authDomain: "suzukiapp-9dd50.firebaseapp.com",
//     projectId: "suzukiapp-9dd50",
//     storageBucket: "suzukiapp-9dd50.appspot.com",
//     messagingSenderId: "516401748398",
//     appId: "1:516401748398:web:59301e6ebdd9015da0503f",
//     measurementId: "G-C53H8L3J3Y"
// }
// initializeApp( firebaseConfig )

ReactDOM.createRoot(document.querySelector(`#app`) as HTMLElement).render(<App />)

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
