import { Expose, Type } from 'class-transformer'
import { IsDefined, IsNumber, ValidateNested } from 'class-validator'
import { Dealership } from '../../entity'

export class DealershipsDTO {
    @Expose()
    @ValidateNested()
    @Type(() => Dealership)
    data!: Dealership[]

    @Expose()
    @IsDefined()
    @IsNumber()
    page!: number

    @Expose()
    @IsDefined()
    @IsNumber()
    pageSize!: number

    @Expose()
    @IsDefined()
    @IsNumber()
    count!: number
}
