import { useDispatch, useSelector } from 'react-redux'
import { Table } from '../../../component'
import { State } from '../../../state'
import { getAllUser } from '../../../action'
import { useNavigate } from 'react-router-dom'
import { GET_ALL_USER_ACTIONS } from '../../../action/users/users.constants'
import { UserType } from '../../../enum/user-type.enum'
import { useTranslation } from 'react-i18next'
import { Sort } from '../../../enum/sort.enum'
import './user-table.scss'

interface UserTableProps {
    onUserSelected?: CallableFunction
}

export const UserTable = (props: UserTableProps) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { t } = useTranslation()

    const { users, order, sort } = useSelector((state: State) => state.users)
    const { page, pageSize, count } = useSelector((state: State) => state.users.pagination)

    const onPageChange = async (page: number) => {
        dispatch({ type: GET_ALL_USER_ACTIONS.GET_ALL_USER_CHANGE_PAGE, page: page })
        await getAllUser()
    }

    const onOrderChange = async (column: string) => {
        let direction = Sort.ASC

        if (order === column) {
            if (sort === Sort.ASC) {
                direction = Sort.DESC
            } else if (sort === Sort.DESC) {
                column = ``
                direction = Sort.EMPTY
            }
        }

        dispatch({ type: GET_ALL_USER_ACTIONS.GET_ALL_USER_CHANGE_ORDER, orderBy: column, sort: direction })
        await getAllUser()
    }

    const columns = [
        {
            canOrder: true,
            caption: t('user_table_name_title'),
            render: (row: any) => row.lastName + ' ' + row.firstName,
            name: `user.lastName`,
            width: 209,
        },
        {
            canOrder: true,
            caption: t('user_table_role_title'),
            render: (data: any) => (data.role == UserType.ADMIN ? t('user_type_admin_title') : data.role == UserType.DEALER ? t('user_type_dealer_title') : data.role == UserType.REVIEWER ? t('user_type_reviewer_title') : t('user_type_user_title')),
            name: `user.role`,
            width: 120,
        },
        {
            canOrder: true,
            caption: t('user_table_email_title'),
            render: (data: any) => data.email,
            name: `user.email`,
            width: 319,
        },
        {
            canOrder: true,
            caption: t('user_table_status_title'),
            render: (row: any) => (row.status == 1 ? t('status_active_title') : t('status_deactivated_title')),
            name: `user.status`,
            width: 122,
        },
        {
            canOrder: true,
            caption: t('user_table_dealership_title'),
            render: (data: any) => (data.dealership != null ? data.dealership?.serviceCode + ' - ' + data.dealership?.serviceName : ''),
            name: `dealership.serviceName`,
            width: 209,
        },
        {
            canOrder: true,
            caption: t('user_table_country_title'),
            render: (data: any) => (data.dealership != null ? data.dealership.country : ''),
            name: `country.name`,
            width: 109,
        },
    ]

    return (
        <Table
            className="user-table"
            columns={columns}
            dataset={users !== null && users !== undefined ? users : []}
            pagination={{ page: page, pageSize: pageSize, count: count, onPageChange: onPageChange }}
            order={{ orderBy: order, sort: sort }}
            onOrderChange={onOrderChange}
            onRowClick={(row) => {
                if (props.onUserSelected) props.onUserSelected(row)
            }}
        />
    )
}
