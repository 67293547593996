import { UserType } from '../enum/user-type.enum'

export const menu = [
    [
        {
            caption: 'menu_warranties_caption',
            path: '/',
            icon: 'ic_camera.svg',
            roles: [UserType.ADMIN, UserType.USER, UserType.DEALER, UserType.REVIEWER],
            includes: ['warranty', 'warranties'],
        },
        {
            caption: 'menu_user_managemnet_caption',
            children: [
                {
                    caption: 'menu_dealers_caption',
                    path: '/users/dealers',
                    icon: 'users.svg',
                    roles: [UserType.ADMIN],
                    includes: [],
                },
                {
                    caption: 'menu_users_caption',
                    path: '/users/users',
                    icon: 'users.svg',
                    roles: [UserType.ADMIN, UserType.DEALER],
                    includes: [],
                },
                {
                    caption: 'menu_reviewers_caption',
                    path: '/users/reviewer',
                    icon: 'users.svg',
                    roles: [UserType.ADMIN],
                    includes: [],
                },
            ],
            path: '/users',
            icon: 'ic_users.svg',
            roles: [UserType.ADMIN, UserType.DEALER],
            includes: [],
        },
        {
            caption: 'menu_dealerships_caption',
            path: '/dealerships',
            icon: 'ic_briefcase.svg',
            roles: [UserType.ADMIN],
            includes: [],
        },
    ],
]
