import { CHANGE_PASSWORD_ACTIONS } from '../action/users/users.constants'
import { ChangePasswordState } from '../state'

const initialChangePasswordState: ChangePasswordState = {
    form: {
        oldPassword: {
            error: '',
            value: '',
        },
        newPassword: {
            error: '',
            value: '',
        },
        newPasswordAgain: {
            error: '',
            value: '',
        },
    },
    changeStarted: null,
}

export const changePasswordReducer = (state: ChangePasswordState = initialChangePasswordState, action: any): ChangePasswordState => {
    switch (action.type) {
        case CHANGE_PASSWORD_ACTIONS.CHANGE_PASSWORD_CHANGE_OLD_PASSWORD:
            return { ...state, form: { ...state.form, oldPassword: { ...state.form.oldPassword, value: action.oldPassword } } }
        case CHANGE_PASSWORD_ACTIONS.CHANGE_PASSWORD_CHANGE_NEW_PASSWORD:
            return { ...state, form: { ...state.form, newPassword: { ...state.form.newPassword, value: action.newPassword } } }
        case CHANGE_PASSWORD_ACTIONS.CHANGE_PASSWORD_CHANGE_NEW_PASSWORD_AGAIN:
            return { ...state, form: { ...state.form, newPasswordAgain: { ...state.form.newPasswordAgain, value: action.newPasswordAgain } } }
        case CHANGE_PASSWORD_ACTIONS.CHANGE_PASSWORD_CLEAR:
            return { ...state, changeStarted: null, form: { ...state.form, oldPassword: { ...state.form.oldPassword, value: '' }, newPassword: { ...state.form.newPassword, value: '' }, newPasswordAgain: { ...state.form.newPasswordAgain, value: '' } } }
        case CHANGE_PASSWORD_ACTIONS.CHANGE_PASSWORD_STARTED:
            return { ...state, changeStarted: new Date() }
        case CHANGE_PASSWORD_ACTIONS.CHANGE_PASSWORD_OK:
            return { ...state, changeStarted: null, form: { ...state.form, oldPassword: { ...state.form.oldPassword, value: '' }, newPassword: { ...state.form.newPassword, value: '' }, newPasswordAgain: { ...state.form.newPasswordAgain, value: '' } } }
        case CHANGE_PASSWORD_ACTIONS.CHANGE_PASSWORD_FAILED:
            return { ...state, changeStarted: null }
        default:
            return state
    }
}
