import { MouseEvent, MouseEventHandler } from 'react'
import { Modal } from '../../../component/modal'
import { Photo } from '../../../entity'
import { useTranslation } from 'react-i18next'
import { WarrantyStatus } from '../../../enum/warranty-status.enum'
import { PhotoEditor } from './editor/photo-editor'
import { useSelector } from 'react-redux'
import { State } from '../../../state'
import { UserType } from '../../../enum/user-type.enum'
import './photo-modal.scss'

interface PhotoModalProps {
    photo: Photo
    warrantyStatus: WarrantyStatus
    onCloseClick?: MouseEventHandler
    onDeleteCallback: CallableFunction
    onEditCallback: CallableFunction
}

export const PhotoModal = (props: PhotoModalProps) => {
    const { t } = useTranslation()
    const { authenticatedUser } = useSelector((state: State) => state.session)

    const onDeleteButtonClick = async (event: MouseEvent) => {
        event.preventDefault()

        if (authenticatedUser?.role !== UserType.REVIEWER) {
            props.onDeleteCallback(props.photo)
        }
    }

    const onEditButtonClick = async (event: MouseEvent) => {
        event.preventDefault()

        if (authenticatedUser?.role !== UserType.REVIEWER) {
            props.onEditCallback(props.photo)
        }
    }

    return (
        <Modal title={t('photo_info_modal_title') + ' ' + props.photo.id} onCloseClick={props.onCloseClick}>
            <PhotoEditor photo={props.photo} editable={false} />

            <div className={`photo-modal__buttons ${props.warrantyStatus === WarrantyStatus.ACCEPTED ? 'photo-modal__hidden' : ''}`}>
                {authenticatedUser?.role !== UserType.REVIEWER ? (
                    <>
                        <button className="button button--secondary secondary" onClick={onDeleteButtonClick}>
                            {t('photo_modal_delete_button')}
                        </button>

                        <button className="button" onClick={onEditButtonClick}>
                            {t('photo_modal_edit_button')}
                        </button>
                    </>
                ) : null}
            </div>
        </Modal>
    )
}
