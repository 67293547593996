import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { ErrorDTO } from '../../dto'
import { store } from '../../store'
import { BASE_ACTIONS, BASE_METHODS } from '../base/base.constants'
import { createCall } from '../base'
import { WarrantyDTO } from '../../dto/warranties'
import { getWarranty } from './get-warranty.action'
import { UPDATE_WARRANTY_STATUS_ACTIONS } from './warranties.constants'
import { WarrantyStatus } from '../../enum/warranty-status.enum'

export const updateWarrantyStatus = async (id: number, status: WarrantyStatus) => {
    store.dispatch({ type: UPDATE_WARRANTY_STATUS_ACTIONS.UPDATE_WARRANTY_STATUS_STARTED })

    const promise = createCall(`warranties/${id}/status?status=${status}`, true, BASE_METHODS.PATCH)
    promise
        .then(async (response) => {
            let body: WarrantyDTO

            try {
                body = plainToClass(WarrantyDTO, JSON.parse(await response.text()))
            } catch (exception) {
                store.dispatch({ type: BASE_ACTIONS.ERROR_INVALID_JSON })
                return
            }

            if ((await validate(body)).length > 0) {
                store.dispatch({ type: BASE_ACTIONS.ERROR_INVALID_RESPONSE })
                return
            }

            store.dispatch({ type: UPDATE_WARRANTY_STATUS_ACTIONS.UPDATE_WARRANTY_STATUS_OK, warranty: body.warranty })

            store.dispatch({ type: BASE_ACTIONS.CLEAR_ERROR })

            getWarranty(id)
        })
        .catch(async (response) => {
            let error: ErrorDTO

            try {
                error = plainToClass(ErrorDTO, JSON.parse(await response.text()))
            } catch (exception) {
                //store.dispatch({ type: response })
                return
            }

            store.dispatch({ type: UPDATE_WARRANTY_STATUS_ACTIONS.UPDATE_WARRANTY_STATUS_FAILED })

            store.dispatch({ type: BASE_ACTIONS.ERROR_MESSAGE, message: error.message, code: error.code })
        })
}
