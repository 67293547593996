import { Expose } from 'class-transformer'
import { IsDefined, IsNumber, IsOptional, IsString, ValidateIf } from 'class-validator'
import { Vector } from './vector.entity'

export class Cover {
    @Expose()
    @IsDefined()
    @IsNumber()
    id!: number

    @Expose()
    @IsDefined()
    @IsNumber()
    type!: number

    @Expose()
    @IsDefined()
    @ValidateIf((object, value) => value !== null)
    position?: Vector | null

    @Expose()
    @IsDefined()
    @IsNumber()
    width!: number | null

    @Expose()
    @IsDefined()
    @IsNumber()
    height!: number | null

    @Expose()
    @IsDefined()
    @IsNumber()
    radius!: number | null

    @Expose()
    @IsDefined()
    @IsNumber()
    rotation!: number | null

    @Expose()
    @IsDefined()
    @ValidateIf((object, value) => value !== null)
    points?: Vector[] | null

    @Expose()
    @IsDefined()
    @IsNumber()
    created!: number

    @IsOptional()
    @IsDefined()
    @IsNumber()
    updated!: number
}
