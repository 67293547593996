import { MouseEvent, MouseEventHandler, ChangeEvent, useRef, useState, createRef, useEffect } from 'react'
import { Modal } from '../../../component/modal'
import { useDispatch, useSelector } from 'react-redux'
import { TextField } from '../../../component/field'
import { State } from '../../../state'
import { useTranslation } from 'react-i18next'
import { Checkbox, Dropdown } from '../../../component'
import { Country, Dealership } from '../../../entity'
import { CREATE_DEALERSHIP_ACTIONS } from '../../../action/dealerships/dealerships.constants'
import { createDealership } from '../../../action/dealerships/create-dealership.action'
import { Language } from '../../../enum/language.enum'
import { Segment } from '../../../enum/segment.enum'
import { Status } from '../../../enum/status.enum'
import './add-dealership-modal.scss'

interface AddDealershipModalProps {
    dealership: Dealership | null
    onModalClose?: CallableFunction

    onCloseClick?: MouseEventHandler
    onCancelClick?: MouseEventHandler
    onOkClick?: MouseEventHandler
}

export const AddDealershipModal = (props: AddDealershipModalProps) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const { createDealershipForm, countries } = useSelector((state: State) => state.dealerships)

    const formElement = createRef<HTMLFormElement>()

    const [nameError, setNameError] = useState('')
    const [codeError, setCodeError] = useState('')
    const [countryError, setCountryError] = useState('')
    const [languageError, setLanguageError] = useState('')
    const [segmentpError, setSegmentError] = useState('')
    const [roleError, setRoleError] = useState('')

    useEffect(() => {
        if (props.dealership) {
            dispatch({ type: CREATE_DEALERSHIP_ACTIONS.CREATE_DEALERSHIP_CHANGE_DEALERSHIP_ID, dealershipId: props.dealership.id })
            dispatch({ type: CREATE_DEALERSHIP_ACTIONS.CREATE_DEALERSHIP_CHANGE_NAME, name: props.dealership.serviceName })
            dispatch({ type: CREATE_DEALERSHIP_ACTIONS.CREATE_DEALERSHIP_CHANGE_CODE, code: props.dealership.serviceCode })
            dispatch({ type: CREATE_DEALERSHIP_ACTIONS.CREATE_DEALERSHIP_CHANGE_COUNTRY, countryName: props.dealership.country, countryId: countries.filter((country: Country) => country.name === props.dealership?.country)[0].id })
            dispatch({ type: CREATE_DEALERSHIP_ACTIONS.CREATE_DEALERSHIP_CHANGE_STATUS, status: props.dealership.status })
            dispatch({ type: CREATE_DEALERSHIP_ACTIONS.CREATE_DEALERSHIP_CHANGE_SEGMENTS, segments: Segment.fromString(props.dealership.segments) })
            dispatch({ type: CREATE_DEALERSHIP_ACTIONS.CREATE_DEALERSHIP_CHANGE_LANGUAGE, language: Language.fromString(props.dealership.language) })
        }
    }, [])

    const onNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        dispatch({ type: CREATE_DEALERSHIP_ACTIONS.CREATE_DEALERSHIP_CHANGE_NAME, name: event.target.value })
    }

    const onCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        dispatch({ type: CREATE_DEALERSHIP_ACTIONS.CREATE_DEALERSHIP_CHANGE_CODE, code: event.target.value })
    }

    const onCountryChange = (countryName: any) => {
        dispatch({ type: CREATE_DEALERSHIP_ACTIONS.CREATE_DEALERSHIP_CHANGE_COUNTRY, countryName: countryName, countryId: countries.filter((country: Country) => country.name == countryName)[0].id })
    }

    const onLanguageChange = (language: any) => {
        dispatch({ type: CREATE_DEALERSHIP_ACTIONS.CREATE_DEALERSHIP_CHANGE_LANGUAGE, language: language })
    }

    const onSegmentChange = (segment: any) => {
        dispatch({
            type: CREATE_DEALERSHIP_ACTIONS.CREATE_DEALERSHIP_CHANGE_SEGMENT,
            segments: segment,
        })
    }

    const onStatusChange = () => {
        dispatch({
            type: CREATE_DEALERSHIP_ACTIONS.CREATE_DEALERSHIP_CHANGE_STATUS,
            status: createDealershipForm.status === Status.ACTIVE ? Status.DEACTIVATED : Status.ACTIVE,
        })
    }

    const onSaveButtonClick = async (event: MouseEvent) => {
        event.preventDefault()

        if (!validateFileds()) {
            return
        }

        if (!formElement.current?.reportValidity()) {
            return
        }

        await createDealership()

        dispatch({ type: CREATE_DEALERSHIP_ACTIONS.CREATE_DEALERSHIP_CLEAR })

        if (props.onModalClose) {
            props.onModalClose()
        }
    }

    const validateFileds = () => {
        setNameError('')
        setCodeError('')
        setLanguageError('')
        setCountryError('')

        if (createDealershipForm.name.value.length === 0) {
            setNameError(t('error_add_dealership_name_empty'))
            return false
        }

        if (createDealershipForm.code.value.length === 0) {
            setCodeError(t('error_add_dealership_code_empty'))
            return false
        }

        if (createDealershipForm.countryName == null) {
            setCountryError(t('error_add_dealership_select_country'))
            return false
        }

        if (createDealershipForm.language == null) {
            setLanguageError(t('error_add_user_select_language'))
            return false
        }

        if (createDealershipForm.segments.length === 0) {
            setSegmentError(t('error_add_dealership_select_segment'))
            return false
        }

        return true
    }

    const getCountryArray = () => {
        const items: [{ id: string; value: string }] = [{ id: '-1', value: t('add_car_choose_title') }]
        countries.map((country, index) => items.push({ id: country.name, value: country.name }))
        items.shift()
        return items
    }

    return (
        <Modal title={props.dealership ? t('dealerships_edit_dealership_title') : t('dealerships_add_dealership_title')} onCloseClick={props.onCloseClick} onCancelClick={props.onCancelClick} onOkClick={props.onOkClick}>
            <form action="" className="add-dealership-modal" ref={formElement}>
                <TextField field={createDealershipForm.name} onChange={onNameChange} placeholder={t('dealerships_add_dealership_name_placeholder')} />
                <p className={`error-message`}>{nameError.length > 0 ? nameError : ''}</p>

                <TextField field={createDealershipForm.code} onChange={onCodeChange} placeholder={t('dealerships_add_dealership_code_placeholder')} />
                <p className={`error-message`}>{codeError.length > 0 ? codeError : ''}</p>

                <Dropdown selectItemTitle={t('dealerships_add_dealership_select_country_title')} value={createDealershipForm.countryName} items={getCountryArray()} error={''} onChange={onCountryChange} />
                <p className={`error-message`}>{countryError.length > 0 ? countryError : ''}</p>

                <Dropdown
                    selectItemTitle={t('dealerships_add_dealership_select_language_title')}
                    value={createDealershipForm.language}
                    items={[
                        { id: Language.HUNGARIAN, value: t('language_hungarian_title') },
                        { id: Language.ENGLISH, value: t('language_english_title') },
                        { id: Language.ROMANIAN, value: t('language_romanian_title') },
                        { id: Language.CROATIAN, value: t('language_croatian_title') },
                        { id: Language.CZECH, value: t('language_czech_title') },
                        { id: Language.SLOVENIAN, value: t('language_slovenian_title') },
                        { id: Language.SLOVAK, value: t('language_slovak_title') },
                    ]}
                    error={''}
                    onChange={onLanguageChange}
                />
                <p className={`error-message`}>{languageError.length > 0 ? languageError : ''}</p>

                <div className="check-box-group">
                    <div className="check-box-container">
                        <label>{t('dealerships_add_dealership_active_title')}</label>

                        <Checkbox
                            checked={createDealershipForm.status === Status.ACTIVE}
                            id="status"
                            onChange={() => {
                                onStatusChange()
                            }}
                        />
                    </div>
                </div>

                <div className="check-box-group">
                    <div className="check-box-container margin-top">
                        <Checkbox
                            checked={
                                createDealershipForm.segments.find((segment) => {
                                    return segment === Segment.AUTOMOBILE
                                }) !== undefined
                            }
                            id="automobile"
                            onChange={() => {
                                onSegmentChange(Segment.AUTOMOBILE)
                            }}
                        />

                        <label>{t('segment_automobile_title')}</label>
                    </div>

                    <div className="check-box-container">
                        <Checkbox
                            checked={
                                createDealershipForm.segments.find((segment) => {
                                    return segment === Segment.MOTORCYCLE
                                }) !== undefined
                            }
                            id="motorcycle"
                            onChange={() => {
                                onSegmentChange(Segment.MOTORCYCLE)
                            }}
                        />

                        <label>{t('segment_motorcycle_title')}</label>
                    </div>

                    <div className="check-box-container">
                        <Checkbox
                            checked={
                                createDealershipForm.segments.find((segment) => {
                                    return segment === Segment.MARINE
                                }) !== undefined
                            }
                            id="marine"
                            onChange={() => {
                                onSegmentChange(Segment.MARINE)
                            }}
                        />

                        <label>{t('segment_marine_title')}</label>
                    </div>
                </div>
                <p className={`error-message`}>{segmentpError.length > 0 ? segmentpError : ''}</p>

                {/* <div className="divider" /> */}

                <div className="add-user-modal__buttons">
                    <button className="button" onClick={onSaveButtonClick}>
                        {t('user_managment_add_user_save_button')}
                    </button>
                </div>
            </form>
        </Modal>
    )
}
