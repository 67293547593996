import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { ErrorDTO } from '../../dto'
import { store } from '../../store'
import { BASE_ACTIONS, BASE_METHODS } from '../base/base.constants'
import { createCall } from '../base'
import { GET_PHOTO_ACTIONS } from './photos.constants'
import { Photo } from '../../entity'

export const getPhotoByHash = async (hash: string) => {
    store.dispatch({ type: GET_PHOTO_ACTIONS.GET_PHOTO_STARTED })

    const promise = createCall(`photos/hash/${hash}`, false, BASE_METHODS.GET)
    promise
        .then(async (response) => {
            let body: Photo

            try {
                body = plainToClass(Photo, JSON.parse(await response.text()))
            } catch (exception) {
                store.dispatch({ type: BASE_ACTIONS.ERROR_INVALID_JSON })
                return
            }

            if ((await validate(body)).length > 0) {
                store.dispatch({ type: BASE_ACTIONS.ERROR_INVALID_RESPONSE })
                return
            }

            store.dispatch({ type: GET_PHOTO_ACTIONS.GET_PHOTO_OK, photo: body })

            store.dispatch({ type: BASE_ACTIONS.CLEAR_ERROR })
        })
        .catch(async (response) => {
            let error: ErrorDTO

            try {
                error = plainToClass(ErrorDTO, JSON.parse(await response.text()))
            } catch (exception) {
                //store.dispatch({ type: response })
                return
            }

            store.dispatch({ type: GET_PHOTO_ACTIONS.GET_PHOTO_FAILED })

            store.dispatch({ type: BASE_ACTIONS.ERROR_MESSAGE, message: error.message, code: error.code })
        })
}
