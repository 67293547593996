import { ChangeEvent, useRef, useState } from 'react'
import './password.field.scss'

interface PasswordFieldProps {
    field: {
        error: string
        required?: boolean
        value: string
    }
    onChange: (event: ChangeEvent<HTMLInputElement>) => void
    placeholder?: string
}

export const PasswordField = (props: PasswordFieldProps) => {
    const inputRef = useRef<HTMLInputElement>(null)
    const [showPassword, setShowPassword] = useState(false)

    const onShowPasswordClick = () => {
        if (inputRef.current?.type === 'password') {
            inputRef.current!!.type = 'text'
        } else {
            inputRef.current!!.type = 'password'
        }
        setShowPassword(!showPassword)
    }

    return (
        <div className={`field${props.field.error.length > 0 ? ` field--error` : ``} field--password`}>
            <input ref={inputRef} onChange={props.onChange} placeholder={props.placeholder} required={props.field.required !== false} type="password" value={props.field.value} />
            <img className={'eye-icon'} alt="Show password icon" src={`${!showPassword ? `/image/ic_eye.svg` : `/image/ic_eye_off.svg`}`} onClick={onShowPasswordClick} />
        </div>
    )
}
