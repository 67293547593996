import { Expose } from 'class-transformer'
import { IsDefined, IsNumber, IsOptional, IsString } from 'class-validator'

export class WarrantyGroup {
    @Expose()
    @IsDefined()
    @IsString()
    vinNumber!: string

    @Expose()
    @IsDefined()
    @IsNumber()
    count!: number

    @Expose()
    @IsDefined()
    @IsNumber()
    segment!: number

    @Expose()
    @IsDefined()
    @IsNumber()
    status!: number

    @IsOptional()
    @IsDefined()
    @IsNumber()
    updated!: number
}
