import { ChangeEvent, createRef, FormEvent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { LoadingIcon } from '../../component/icon'
import { PasswordField } from '../../component/field'
import { State } from '../../state'
import { RESET_PASSWORD_ACTIONS } from '../../action/users/users.constants'
import { resetPassword } from '../../action/users/reset-password.action'
import { useTranslation } from 'react-i18next'
import { validatePassword } from '../../helper/validate.helper'
import { validateToken } from '../../action'
import { MessageModal } from '../../component/modal'
import './reset-password.page.scss'

export const ResetPasswordPage = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { t } = useTranslation()

    const { form, createStarted, resetPasswordRequested, validToken } = useSelector((state: State) => state.resetPassword)
    const formElement = createRef<HTMLFormElement>()
    const [searchParams, setSearchParams] = useSearchParams()
    const token = searchParams.get('token')
    const language = searchParams.get('language')
    const forgotten = searchParams.get('forgotten')

    const [passwordError, setPasswordError] = useState('')
    const [newPasswordError, setNewPasswordError] = useState('')

    useEffect(() => {
        if (token !== undefined && token !== null && token.length) {
            validateToken(token)

            dispatch({ type: RESET_PASSWORD_ACTIONS.RESET_PASSWORD_SET_TOKEN_AND_FORGOTTEN, token: token, forgotten: forgotten === 'true' })
        }
    }, [])

    const onPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        dispatch({ type: RESET_PASSWORD_ACTIONS.RESET_PASSWORD_CHANGE_PASSWORD, password: event.target.value })
    }

    const onNewPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        dispatch({ type: RESET_PASSWORD_ACTIONS.RESET_PASSWORD_CHANGE_NEW_PASSWORD, newPassword: event.target.value })
    }

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()

        if (!validateFileds()) {
            return
        }

        if (!formElement.current?.reportValidity()) {
            return
        }

        await resetPassword()
    }

    const validateFileds = () => {
        setPasswordError('')
        setNewPasswordError('')

        const passwordValidation = validatePassword(form.password.value)
        if (passwordValidation != null) {
            setPasswordError(t(passwordValidation))
            return false
        }

        const newPasswordValidation = validatePassword(form.newPassword.value)
        if (newPasswordValidation != null) {
            setNewPasswordError(t(newPasswordValidation))
            return false
        }

        if (form.password.value !== form.newPassword.value) {
            setNewPasswordError(t('error_change_password_password_mismatch'))
            return false
        }

        return true
    }

    return (
        <main className="page--reset-password">
            <div className="reset-password-form">
                {resetPasswordRequested !== true ? (
                    <div>
                        <div className="title-container">
                            <button
                                className="button-back"
                                onClick={(e) => {
                                    e.preventDefault()

                                    dispatch({ type: RESET_PASSWORD_ACTIONS.RESET_PASSWORD_CHANGE_PASSWORD, password: '' })
                                    dispatch({ type: RESET_PASSWORD_ACTIONS.RESET_PASSWORD_CHANGE_NEW_PASSWORD, newPassword: '' })

                                    navigate(`/login`)
                                }}
                            >
                                <img alt="Vissza gomb" className="back" src="/image/ic_arrow_left.svg" />
                            </button>

                            <h2>{t('reset_password_title')}</h2>
                        </div>
                        <label className="description">{t('reset_password_description')}</label>

                        <div className="margin-bottom" />

                        <form onSubmit={onSubmit} id="reset-password-form" ref={formElement}>
                            <PasswordField field={form.password} onChange={onPasswordChange} placeholder={t('reset_password_new_password_placeholder')} />
                            <p className={`field-message${passwordError.length > 0 ? ` field-message--error` : ``}`}>{passwordError.length > 0 ? passwordError : t('reset_password_new_password_hint')}</p>

                            <PasswordField field={form.newPassword} onChange={onNewPasswordChange} placeholder={t('reset_password_new_password_again_placeholder')} />
                            <p className={`field-message${newPasswordError.length > 0 ? ` field-message--error` : ``}`}>{newPasswordError.length > 0 ? newPasswordError : t('reset_password_new_password_again_hint')}</p>

                            <footer>
                                <button className="button  margin-top" disabled={createStarted !== null} form="reset-password-form" type="submit">
                                    {createStarted !== null ? <LoadingIcon /> : t('reset_password_create_button')}
                                </button>
                            </footer>
                        </form>
                    </div>
                ) : (
                    <>
                        <h2>{t('reset_password_success_title')}</h2>

                        <label className="description">{t('reset_password_success_description')}</label>

                        <footer>
                            <button
                                className="button"
                                onClick={(e) => {
                                    e.preventDefault()
                                    dispatch({ type: RESET_PASSWORD_ACTIONS.RESET_PASSWORD_FAILED })

                                    navigate(`/login`)
                                }}
                            >
                                {t('reset_password_back_button')}
                            </button>
                        </footer>
                    </>
                )}

                {!validToken ? <MessageModal title={t('error_invalid_token')} message={''} onCloseClick={() => {}} /> : null}
            </div>
        </main>
    )
}
