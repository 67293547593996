import { GET_PHOTO_ACTIONS } from '../action/photos/photos.constants'
import { PhotoState } from '../state'

const initialPhotoState: PhotoState = {
    photo: undefined,
    loadingStarted: null,
}

export const photoReducer = (state: PhotoState = initialPhotoState, action: any): PhotoState => {
    switch (action.type) {
        case GET_PHOTO_ACTIONS.GET_PHOTO_OK:
            return { ...state, photo: action.photo, loadingStarted: null }
        case GET_PHOTO_ACTIONS.GET_PHOTO_STARTED:
            return { ...state, loadingStarted: new Date() }
        case GET_PHOTO_ACTIONS.GET_PHOTO_FAILED:
            return { ...state, photo: null, loadingStarted: null }
        default:
            return state
    }
}
