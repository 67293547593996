import { combineReducers, legacy_createStore as createStore } from 'redux'
import { baseReducer, loginReducer, sessionReducer, forgotPasswordReducer, changePasswordReducer, resetPasswordReducer, warrantiesReducer, photoReducer, warrantyGroupReducer,
     warrantyReducer, usersReducer, dealershipsReducer, editorReducer } from '../reducer'

export const store = createStore(
    combineReducers({
        base: baseReducer,
        login: loginReducer,
        forgotPassword: forgotPasswordReducer,
        resetPassword: resetPasswordReducer,
        changePassword: changePasswordReducer,
        session: sessionReducer,
        warranties: warrantiesReducer,
        warrantyGroup: warrantyGroupReducer,
        warranty: warrantyReducer,
        users: usersReducer,
        dealerships: dealershipsReducer,
        photo: photoReducer,
        editor: editorReducer,
    })
)
