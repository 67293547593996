import { plainToClass } from 'class-transformer'
import { ErrorDTO } from '../../dto'
import { store } from '../../store'
import { BASE_ACTIONS, BASE_METHODS } from '../base/base.constants'
import { createCall } from '../base'
import { EXPORT_USERS_ACTION } from './users.constants'

export const exporUsers = async () => {
    store.dispatch({ type: EXPORT_USERS_ACTION.EXPORT_USERS_ACTION_STARTED })

    const promise = createCall(`users/data/export`, true, BASE_METHODS.GET)
    promise
        .then(async (response) => {
            response.blob().then((blob) => {
                const url = window.URL.createObjectURL(blob)
                const a = document.createElement('a')
                a.href = url
                a.download = `users_${new Date().getTime()}.xlsx`
                a.click()
            })

            store.dispatch({ type: EXPORT_USERS_ACTION.EXPORT_USERS_ACTION_OK })

            store.dispatch({ type: BASE_ACTIONS.CLEAR_ERROR })
        })
        .catch(async (response) => {
            let error: ErrorDTO

            try {
                error = plainToClass(ErrorDTO, JSON.parse(await response.text()))
            } catch (exception) {
                //store.dispatch({ type: response })
                return
            }
            store.dispatch({ type: EXPORT_USERS_ACTION.EXPORT_USERS_ACTION_FAILED })

            store.dispatch({ type: BASE_ACTIONS.ERROR_MESSAGE, message: error.message, code: error.code })
        })
}
