import { useSelector } from 'react-redux'
import { UsersDTO } from '../../dto'
import { UserType } from '../../enum/user-type.enum'
import { MenuElement } from './menu-element'
import './menu-group.scss'
import { State } from '../../state'

interface MenuGroupProps {
    menuGroup: any
    open?: boolean
    onMobileMenuToggle?: (open: boolean) => void
}

export const MenuGroup = (props: MenuGroupProps) => {
    const authenticatedUser = useSelector((state: State) => state.session.authenticatedUser)

    const menuElements: JSX.Element[] = []
    props.menuGroup
        .filter((e: any) => e.roles.includes(authenticatedUser?.role))
        .forEach((menuElement: any) => {
            menuElements.push(<MenuElement key={menuElement.path} menuElement={menuElement} onMobileMenuToggle={props.onMobileMenuToggle} />)
        })

    return <ul className="menu__menu-group">{props.open !== false ? menuElements : null}</ul>
}
