import { plainToClass } from 'class-transformer'
import { validate } from 'class-validator'
import { DealershipsDTO, ErrorDTO } from '../../dto'
import { store } from '../../store'
import { BASE_ACTIONS, BASE_METHODS } from '../base/base.constants'
import { createCall } from '../base'
import { GET_DEALERSHIPS_ACTIONS } from './dealerships.constants'

export const getDealerships = async () => {
    store.dispatch({ type: GET_DEALERSHIPS_ACTIONS.GET_DEALERSHIPS_STARTED })

    const page = store.getState().dealerships.pagination.page
    const search = store.getState().dealerships.search
    const orderBy = store.getState().dealerships.order
    const sort = store.getState().dealerships.sort

    const promise = createCall(`dealerships/?page=${page}&pageSize=${15}${search !== '' ? `&search=${search}` : ``}&orderBy=${orderBy}&sort=${sort}`, true, BASE_METHODS.GET)
    promise
        .then(async (response) => {
            let body: DealershipsDTO

            try {
                body = plainToClass(DealershipsDTO, JSON.parse(await response.text()))
            } catch (exception) {
                store.dispatch({ type: BASE_ACTIONS.ERROR_INVALID_JSON })
                return
            }

            if ((await validate(body)).length > 0) {
                store.dispatch({ type: BASE_ACTIONS.ERROR_INVALID_RESPONSE })
                return
            }

            store.dispatch({ type: GET_DEALERSHIPS_ACTIONS.GET_DEALERSHIPS_OK, dealerships: body.data, page: body.page, pageSize: body.pageSize, count: body.count })

            store.dispatch({ type: BASE_ACTIONS.CLEAR_ERROR })
        })
        .catch(async (response) => {
            let error: ErrorDTO

            try {
                error = plainToClass(ErrorDTO, JSON.parse(await response.text()))
            } catch (exception) {
                //store.dispatch({ type: response })
                return
            }

            store.dispatch({ type: GET_DEALERSHIPS_ACTIONS.GET_DEALERSHIPS_FAILED })

            store.dispatch({ type: BASE_ACTIONS.ERROR_MESSAGE, message: error.message, code: error.code })
        })
}
