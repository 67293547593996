import { useTranslation } from 'react-i18next'
import { MouseEvent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Photo } from '../../../entity'
import { PhotoInfoModal } from './photo-info-modal'
import { PhotoModal } from './photo-modal'
import { MessageModal } from '../../../component/modal'
import { WarrantyStatus } from '../../../enum/warranty-status.enum'
import { deletePhoto } from '../../../action/photos'
import { EditPhotoModal } from './edit-photo-modal'
import { PHOTO_EDITOR_ACTIONS } from '../../../action/photos/photos.constants'
import { uploadPhotosData } from '../../../action/photos/upload-photos-data.action'
import { PhotoStatus } from '../../../enum/photo-status.enum'
import { State } from '../../../state'
import './warranty-card.scss'

interface WarrantyCardProps {
    warrantyStatus: WarrantyStatus
    photo: Photo
    warrantyId: number
}

export const WarrantyCard = (props: WarrantyCardProps) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const { loadingStarted } = useSelector((state: State) => state.editor)

    const [infoModalVisible, setInfoModalVisible] = useState(false)
    const [photoModalVisible, setPhotoModalVisible] = useState(false)
    const [deletePhotoModalVisible, setDeletePhotoModalVisible] = useState(false)
    const [selectedPhoto, setSelectedPhoto] = useState(null as Photo | null)
    const [editPhotoMessageModalVisible, setEditPhotoMessageModalVisible] = useState(false)
    const [editPhotoModalVisible, setEditPhotoModalVisible] = useState(false)

    const onPhotoClick = async (event: MouseEvent) => {
        event.preventDefault()

        setPhotoModalVisible(!photoModalVisible)
    }

    const onInfoButtonClick = async (event: MouseEvent) => {
        event.preventDefault()

        setInfoModalVisible(!infoModalVisible)
    }

    const onCopyLinkButtonClick = async (event: MouseEvent) => {
        event.preventDefault()

        // navigator.clipboard.writeText(window.location.origin + `/photo/${props.photo.hash}`)
        navigator.clipboard.writeText(props.photo.coveredUrl)

        // Get the snackbar DIV
        var x = document.getElementById('snackbar')

        // Add the "show" class to DIV
        x!!.className = 'show'

        // After 3 seconds, remove the show class from DIV
        setTimeout(function () {
            x!!.className = x!!.className.replace('show', '')
        }, 3000)
    }

    return (
        <div className="warranty-card">
            <div className="photo-container" onClick={onPhotoClick}>
                {/* <img alt="Photo" className="photo" src={`${props.photo.coveredUrl}?${new Date().getTime()}`} /> */}
                <img alt="Photo" className="photo" src={props.photo.coveredUrl} />

                <img alt="Status icon" className="status" src={props.photo.metadata?.status === PhotoStatus.UPLOADED ? '/image/ic_check.svg' : '/image/ic_circle.svg'} />

                {props.photo.metadata?.created!! - props.photo.metadata?.mobileCreated!! > 600 ? <label className="offline">{t('warranty_card_offline')}</label> : null}
            </div>

            <div className="warranty-card__buttons">
                <button className="button" onClick={onInfoButtonClick}>
                    {t('warranty_card_info_button')}
                </button>

                <button className="button" disabled={props.warrantyStatus === WarrantyStatus.ACCEPTED ? false : true} onClick={onCopyLinkButtonClick}>
                    {t('warranty_card_copy_link_button')}
                </button>
            </div>

            {infoModalVisible ? (
                <PhotoInfoModal
                    photo={props.photo}
                    onCloseClick={() => {
                        setInfoModalVisible(!infoModalVisible)
                    }}
                />
            ) : null}

            {photoModalVisible ? (
                <PhotoModal
                    photo={props.photo}
                    warrantyStatus={props.warrantyStatus}
                    onDeleteCallback={(photo: Photo) => {
                        setPhotoModalVisible(!photoModalVisible)

                        setSelectedPhoto(photo)
                        setDeletePhotoModalVisible(!deletePhotoModalVisible)
                    }}
                    onEditCallback={(photo: Photo) => {
                        setPhotoModalVisible(!photoModalVisible)

                        setSelectedPhoto(photo)

                        setEditPhotoMessageModalVisible(!editPhotoMessageModalVisible)
                    }}
                    onCloseClick={() => {
                        setSelectedPhoto(null)

                        setPhotoModalVisible(!photoModalVisible)
                    }}
                />
            ) : null}

            {deletePhotoModalVisible ? (
                <MessageModal
                    title={t('delete_photo_modal_title')}
                    message={t('delete_photo_modal_message')}
                    okTitle={t('button_delete')}
                    onCloseClick={() => {
                        setSelectedPhoto(null)

                        setDeletePhotoModalVisible(!deletePhotoModalVisible)
                    }}
                    onCancelClick={() => {
                        setSelectedPhoto(null)

                        setDeletePhotoModalVisible(!deletePhotoModalVisible)
                    }}
                    onOkClick={async () => {
                        await deletePhoto(selectedPhoto!!.id, props.warrantyId)

                        setSelectedPhoto(null)

                        setDeletePhotoModalVisible(!deletePhotoModalVisible)
                    }}
                />
            ) : null}

            {editPhotoMessageModalVisible ? (
                <MessageModal
                    title={t('edit_photo_modal_title')}
                    message={t('edit_photo_modal_message')}
                    okTitle={t('button_edit')}
                    onCloseClick={() => {
                        setSelectedPhoto(null)

                        setEditPhotoMessageModalVisible(!editPhotoMessageModalVisible)
                    }}
                    onCancelClick={() => {
                        setSelectedPhoto(null)

                        setEditPhotoMessageModalVisible(!editPhotoMessageModalVisible)
                    }}
                    onOkClick={async () => {
                        setSelectedPhoto(null)

                        setEditPhotoMessageModalVisible(!editPhotoMessageModalVisible)

                        setEditPhotoModalVisible(!editPhotoModalVisible)
                    }}
                />
            ) : null}

            {editPhotoModalVisible ? (
                <EditPhotoModal
                    warrantyId={props.warrantyId}
                    photo={props.photo}
                    onCloseClick={() => {
                        dispatch({ type: PHOTO_EDITOR_ACTIONS.CLEAR })

                        setSelectedPhoto(null)

                        setEditPhotoModalVisible(!editPhotoModalVisible)
                    }}
                    onCancelCallback={() => {
                        dispatch({ type: PHOTO_EDITOR_ACTIONS.CLEAR })

                        setSelectedPhoto(null)

                        setEditPhotoModalVisible(!editPhotoModalVisible)
                    }}
                    onAcceptCallback={() => {
                        uploadPhotosData(props.warrantyId)

                        setSelectedPhoto(null)

                        setEditPhotoModalVisible(!editPhotoModalVisible)
                    }}
                />
            ) : null}

            {loadingStarted === null ? (
                <MessageModal
                    title={t('save_photo_modal_title')}
                    message={''}
                    onCloseClick={() => {
                        dispatch({ type: PHOTO_EDITOR_ACTIONS.CLEAR })
                    }}
                    onOkClick={async () => {
                        dispatch({ type: PHOTO_EDITOR_ACTIONS.CLEAR })
                    }}
                />
            ) : null}

            <div id="snackbar">{t('warranty_card_copy_link_message')}</div>
        </div>
    )
}
