import { PHOTO_EDITOR_ACTIONS, UPLOAD_PHOTOS_DATA_ACTIONS } from '../action/photos/photos.constants'
import { Cover } from '../entity'
import { EditorState } from '../state'

const initialEditorState: EditorState = {
    photo: undefined,
    loadingStarted: undefined,
    covers: [],
    lastCoverId: -1,
    isToolsEnabled: false,
    tool: null,
    acceptCover: null,
}

export const editorReducer = (state: EditorState = initialEditorState, action: any): EditorState => {
    switch (action.type) {
        case PHOTO_EDITOR_ACTIONS.INITIALIZE:
            let array: Cover[] = []
            array = [...action.photo.metadata.covers]

            return { ...state, photo: action.photo, loadingStarted: undefined, covers: array, tool: null, isToolsEnabled: false, lastCoverId: -1 }
        case PHOTO_EDITOR_ACTIONS.ADD_COVER:
            const coverArray = state.covers

            const indexOfCover = coverArray.findIndex((c) => {
                return c.id === action.cover.id
            })

            if (indexOfCover !== -1) {
                coverArray.splice(indexOfCover, 1)
            }

            coverArray.push(action.cover)

            return { ...state, covers: coverArray, lastCoverId: action.cover.id }
        case PHOTO_EDITOR_ACTIONS.REMOVE_COVER:
            const coversArray = state.covers

            const indexOfObject = coversArray.findIndex((cover) => {
                return cover.id === state.lastCoverId
            })

            if (indexOfObject !== -1) {
                coversArray.splice(indexOfObject, 1)
            }

            return { ...state, covers: coversArray, lastCoverId: -1 }
        case PHOTO_EDITOR_ACTIONS.DELETE_ALL_COVER:
            return { ...state, covers: [], lastCoverId: -1 }
        case PHOTO_EDITOR_ACTIONS.CLEAR:
            return { ...state, photo: null, loadingStarted: undefined, covers: [], tool: null, isToolsEnabled: false, lastCoverId: -1 }
        case PHOTO_EDITOR_ACTIONS.IS_TOOLS_ENABLED:
            return { ...state, isToolsEnabled: action.enabled }
        case PHOTO_EDITOR_ACTIONS.SELECTED_TOOL:
            return { ...state, tool: action.tool }
        case PHOTO_EDITOR_ACTIONS.ACCEPT_COVER:
            return { ...state, acceptCover: action.acceptCover }

        case UPLOAD_PHOTOS_DATA_ACTIONS.UPLOAD_PHOTOS_DATA_ACTIONS_STARTED:
            return { ...state, loadingStarted: new Date() }
        case UPLOAD_PHOTOS_DATA_ACTIONS.UPLOAD_PHOTOS_DATA_ACTIONS_OK:
            return { ...state, loadingStarted: null }
        case UPLOAD_PHOTOS_DATA_ACTIONS.UPLOAD_PHOTOS_DATA_ACTIONS_FAILED:
            return { ...state, loadingStarted: undefined }
        default:
            return state
    }
}
